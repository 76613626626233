import './Home.css';
import turningPost from '../../assets/blog-img copy.jpg'
import introPost from '../../assets/blog-img.jpg'
import { Link } from 'react-router-dom';
import { UnitCard } from '../../components';
import { useLoaderData } from 'react-router-dom';
import { Unit } from '../../types';

const Home: React.FC = () => {
  const unitList = useLoaderData() as Unit[];

  return (
    <div className='home'>
      <h2 style={{fontWeight: 400, marginBlockEnd: '.25rem'}}>ReCoop Exclusives</h2>
      <p style={{margin: 0, fontSize: '1rem'}}>Be the first to browse exclusive listings before they hit the market.</p>
      <div className='properties' style={{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: '1.25rem', marginBlockStart: '.5rem', zIndex: '1'}}>
        {unitList && (
        <>
        <>{console.log(unitList)}</>
          {unitList.map((unit) => (
              <UnitCard
                key={unit.id}
                unit={unit}
                startDate={null}
                endDate={null}
                guests={null}
              />
          ))}
        </>
        )}
      </div>
      <h2 style={{fontWeight: 400, marginBlockEnd: '.25rem'}}>Newsroom</h2>
      <p style={{margin: 0, fontSize: '1rem'}}>Want the inside scoop behind what we’re building? Follow our journey by reading our blog.</p>
      <div className='blog' style={{display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '2rem', marginBlockStart: '.5rem'}}>
        <Link to="https://docs-archive.recooprentals.com/blog/turning-rental-living-into-wealth-building">
          <img src={turningPost} alt="blog-post-1" />
          <h4 style={{marginBlockEnd: 0, marginBlockStart: '.5rem'}}>Turning Rental Living into Wealth Building</h4>
          <p style={{fontSize: '1rem'}}>May 8, 2023</p>
        </Link>
        <Link to="https://docs-archive.recooprentals.com/blog/introducing-recoop-rentals">
          <img src={introPost} alt="blog-post-1" />
          <h4 style={{marginBlockEnd: 0, marginBlockStart: '.5rem'}}>Introducing ReCoop Rentals</h4>
          <p style={{fontSize: '1rem'}}>May 7, 2023</p>
        </Link>
      </div>
    </div>
  );
}

export default Home;
