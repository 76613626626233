import './Account.css'
import { ReactComponent as ProfileIcon} from '../../assets/security-pass.svg';
import { ReactComponent as BookingsIcon} from '../../assets/RECEPTIONIST.svg';
import { ReactComponent as PaymentsIcon} from '../../assets/BILL.svg';
import { ReactComponent as BetaTestingIcon} from '../../assets/beta.svg';
import { useWallet } from "@aptos-labs/wallet-adapter-react";
import { NavLink, useLoaderData, useNavigate } from "react-router-dom";
import { UserTenantInfo } from "../../types";
import { useEffect } from 'react';

const Account: React.FC = () => {
  const user = useLoaderData() as UserTenantInfo;
  const { account } = useWallet();
  const navigate = useNavigate();

  useEffect(() => {
    if(!account) {
      navigate('/booking-archive');
    }
  }, [account, navigate]);

  return (
    <div className='account'>
      <h2 style={{marginBlockEnd: 0, marginBlockStart: '1.75rem'}}>Account</h2>
      <p style={{marginBlock: 0, fontSize: '.9rem'}}>Address: {`${account?.address.slice(0, 5)}...${account?.address.slice(-5)}`}</p>
      {user?.tenant ? <p style={{marginBlock: 0}}>{user.tenant.first_name} {user.tenant.last_name}, {user.tenant.email_address}</p>
      : <p style={{marginBlock: 0, color: 'red'}}>No profile saved</p>}
      <div style={{marginBlockStart: '2rem'}}>
        <div className='switcher' style={{marginBlockEnd: 'var(--s1)'}}>
          <NavLink to={'personal-info'}>
            <div className='account-tile'>
              <ProfileIcon />
              <h3>Personal Info</h3>
              <p>Provide personal details and how we can reach you</p>
            </div>
          </NavLink>
          <NavLink to={'bookings'}>
            <div className='account-tile'>
              <BookingsIcon />
              <h3>Bookings</h3>
              <p>View and manage all current and past bookings</p>
            </div>
          </NavLink>
        </div>
        <div className='switcher'>
          <NavLink to={'payments'}>
            <div className='account-tile'>
              <PaymentsIcon />
              <h3>Payments</h3>
              <p>Review payments, points earned, coupons, and gift cards</p>
            </div>
          </NavLink>
          <NavLink to={'beta-testing'}>
            <div className='account-tile'>
              <BetaTestingIcon />
              <h3>Beta Testing</h3>
              <p>Provide feedback and test new features of ReCoop Rentals</p>
            </div>
          </NavLink>
        </div>
      </div>
      {user?.tenant && <div className='center' style={{textAlign: 'center', marginBlockStart: '4rem'}}>
        <p>Need to deactivate your account?</p>
        <p style={{textDecoration: 'underline'}}>Take care of that now</p>
      </div>}
    </div>
);
}

export default Account;
