import React from 'react';
import './Preloader.css';
import Logo from '../../assets/logo192.png'

const Preloader: React.FC = () => {
  return(
    <div className="spinner">
      <div className='logo'>
        <img  src={Logo} alt="logo" />
        <div className='load'>
          <span className="dot">.</span><span className="dot">.</span><span className="dot">.</span>
        </div>
      </div>
    </div>
  );
}

export default Preloader;
