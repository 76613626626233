import { Header, Footer, HomeBanner, FlashMessageBox, Preloader } from "../components";
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { useLocation, Outlet } from 'react-router-dom';
import { useState, useEffect } from 'react'; // Add useCallback import
import ResponsiveWrapper from '../components/ResponsiveWrapper/ResponsiveWrapper';


const RootLayout: React.FC = () => {
  const location = useLocation();
  const [isHomePage, setIsHomePage] = useState<boolean>(false);
  const [imageLoaded, setImageLoaded] = useState<boolean>(false);
  const [imageSrc, setImageSrc] = useState<string>('');

  useEffect(() => {
    setIsHomePage(location.pathname === '/booking-archive');
  }, [location.pathname]);

  useEffect(() => {
    const img = new Image();
    img.src = require("../assets/MainPosterImage.png");
    img.onload = () => {
      setImageLoaded(true);
      setImageSrc(img.src);
    };
  }, []);

  if (!imageLoaded) {
    return <Preloader />; // return the Preloader component while loading
  }

  return (
    <ResponsiveWrapper>
      <div className="cover" style={{position: 'relative'}}>
        <div className={`${isHomePage ? 'bg-image banner' : '' }`}
        style={isHomePage ? { backgroundImage: `url(${imageSrc})` } : {}}>
          <Header/>
          {isHomePage && <HomeBanner />}
        </div>
        <main>
          <Outlet />
        </main>
        <FlashMessageBox />
        <ToastContainer />
        <Footer />
      </div>
    </ResponsiveWrapper>
);
}

export default RootLayout;
