import { useLoaderData, NavLink, useNavigate } from "react-router-dom";
import {  useEffect } from "react";
import { PaymentInfo } from "../../types";
import { useWallet } from "@aptos-labs/wallet-adapter-react";
import './Payments.css'
import { ReactComponent as PaymentsIcon} from '../../assets/CHEQUE.svg';
import { ReactComponent as ChevronIcon} from '../../assets/forward.svg';

const Payments: React.FC = () => {
  const payments = useLoaderData() as PaymentInfo[] | { error: string };
  const { account } = useWallet();
  const navigate = useNavigate();

  useEffect(() => {
    if(!account) {
      navigate('/booking-archive');
    }
  }, [account, navigate]);

  // if (payments === null) {
  //   return <div className="box center site-border" style={{marginBlockStart: '5rem', textAlign:'center'}}>Loading...</div>;
  // }

  // if ("error" in payments) {
  //   return <div className="box center site-border" style={{marginBlockStart: '5rem', textAlign:'center'}}>{payments.error}</div>;
  // }

    const getImagePath = (unit_id: number, location: string) => {
      const city = location.split(',')[0].replace(' ', '_');
      const propertyNumber = (unit_id - 1) % 5;
      const imageNumber = '0'; // You can replace this with the desired image number
      return `/Test_Properties/${city}/${propertyNumber}/${imageNumber}.png`;
    };

  return (
    <div className="payments">
      <div className="breadcrumb">
        <NavLink to={'/booking-archive/my-account'}>Account</NavLink>
        <p>{'\u203A'}</p>
        <p>Payments</p>
      </div>
      <h2 style={{marginBlockEnd: 0, marginBlockStart: '.5rem'}}>Payments & payouts</h2>
      <p style={{margin: 0, fontSize: '.9rem'}}>Address: {`${account?.address.slice(0, 5)}...${account?.address.slice(-5)}`}</p>
      <div style={{backgroundColor: 'white', border: '1px solid var(--color-lightish)', borderRadius: 'var(--border-radius)', marginBlockStart: '2.5rem', display: 'flex', gap: '2rem'}}>
        <div style={{padding: '1.5rem', width: '30%'}}>
          <PaymentsIcon style={{marginBlock: '-.75rem'}}/>
          <h3 style={{margin: 0}}>Make all payments through ReCoop</h3>
          <p style={{marginBlockEnd: 0, fontSize: '.92rem', marginBlockStart: '.25rem'}}>Always pay and communicate through ReCoop to ensure that you’re protected under our <span className="link">Terms of Service</span>, <span className="link">Payments Terms of Service</span>, cancelation, and other safeguards. <br /><span className="link">Learn more</span></p>
        </div>
        <div className="bg-image"></div>
      </div>
      {payments && (
        <>
          <h3 style={{fontWeight: '400', marginBlockStart: '2rem', marginBlockEnd: '.5rem'}}>Your Payments</h3>
          <p style={{fontSize: '1rem', margin: 0}}>Completed</p>
        </>
      )}
      {payments !== null && !("error" in payments) && payments.map((payment) => (
        <div className="payment-card" key={payment.id} style={{marginBlockStart: '2rem', display: 'flex', justifyContent: 'space-between'}}>
          <div className="past" style={{display: 'flex'}}>
            <img src={getImagePath(payment.unit_id, payment.unit_location.property.address)} alt="main-unit" />
            <div style={{display: 'flex', flexDirection: 'column', marginInlineStart: '1rem'}}>
              <h5 style={{whiteSpace: 'nowrap', marginBlockStart: '.75rem', marginBlockEnd: 0}}>
                Paid - {new Date(payment.payment_date).toLocaleDateString("en-US", {
                      month: "short",
                      day: "numeric",
                    })}</h5>
              <a href={`https://explorer.aptoslabs.com/txn/${payment.payment_txn}?network=testnet`} target="_blank" rel="noopener noreferrer">
                <p style={{whiteSpace: 'nowrap', fontSize: '.9rem', fontWeight: '300', margin: 0}}>Settled Transaction</p>
              </a>
              <div style={{display: 'flex', gap: '1rem'}}>
              <p style={{whiteSpace: 'nowrap', fontSize: '.83rem', fontWeight: '300', margin: 0}}>{payment.unit_location.property.address.split(',')[0]}</p>
                <p style={{whiteSpace: 'nowrap', fontSize: '.83rem', fontWeight: '300', margin: 0}}>{new Date(payment.block_start_date).toLocaleDateString("en-US", {
                  month: "short",
                  day: "numeric",
                })} - {new Date(payment.block_end_date).toLocaleDateString("en-US", {
                  month: "short",
                  day: "numeric",
                })}</p>
              </div>
            </div>
          </div>
          <div style={{display: 'flex', alignItems: 'center'}}>
          <div style={{display: 'flex', flexDirection:'column', alignItems: 'flex-end'}}>
            <h5 style={{whiteSpace: 'nowrap', marginBlockStart: '.75rem', marginBlockEnd: 0}}>${(payment.payment_amount / 10**6).toFixed(2)}</h5>
            <p style={{fontSize: '.9rem', margin: 0, fontWeight: '300'}}>USD</p>
          </div>
          <ChevronIcon />
          </div>
        </div>
      ))}
    </div>
  );
  }

  export default Payments;
