import './UnitList.css';
import { useState, useEffect } from 'react';
import { UnitCard } from '../../components';
import { useLoaderData, useParams } from 'react-router-dom';
import { Unit } from '../../types';

interface Filters {
  startDate: string | null;
  endDate: string | null;
  guests: number | null;
}

const UnitList: React.FC = () => {
  const unitList = useLoaderData() as Unit[];

  const [filters, setFilters] = useState<Filters>({
    startDate: null,
    endDate: null,
    guests: null,
  });

  const { searchQuery } = useParams<{ searchQuery: string }>();
  const queryParams = new URLSearchParams(searchQuery);
  const startDateParam = queryParams.get('start_date');
  const endDateParam = queryParams.get('end_date');
  const guestsParam = queryParams.get('guests');

  useEffect(() => {
    if (startDateParam && endDateParam) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        startDate: startDateParam,
        endDate: endDateParam,
      }));
    }

    if (guestsParam) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        guests: parseInt(guestsParam),
      }));
    }
  }, [startDateParam, endDateParam, guestsParam]);


  return (
    <div className="grid unit-list">
      {unitList && (
        <>
          {unitList.map((unit) => (
              <UnitCard
                key={unit.id}
                unit={unit}
                startDate={filters.startDate}
                endDate={filters.endDate}
                guests={filters.guests}
              />
          ))}
        </>
        )}
    </div>
  );
}

export default UnitList;
