import { createBrowserRouter, RouterProvider } from "react-router-dom";
import React from 'react';
import 'react-dates/initialize';
import { config } from './config';
import { RootLayout, NewLayout } from './layouts';
import { Archive, Landing, Book, Buy, Upgrade, UnitShow, UnitList, Account, Bookings, Payments, Error, AccountV2, BetaTesting, Home } from './pages';
import { useWallet } from '@aptos-labs/wallet-adapter-react';
import { getOwnedNestEggs } from "./actions";

const App: React.FC = () => {
  const { account } = useWallet();

  const router = createBrowserRouter([
    {
      path: "/",
      element: <NewLayout />,
      errorElement: <Error />,
      children: [
        {
          index: true,
          element: <Landing />,
        },
        {
          path: "archive",
          element: <Archive />,
        },
      ],
    },
    {
      path: "/booking-archive",
      element: <RootLayout />,
      errorElement: <Error />,
      children: [
        {
          index: true,
          element: <Home />,
          loader: async () => {
            return fetch(`${config.apiHost}units/home`);
          }
        },
        {
          path: "book",
          element: <Book />,
          children: [
            {
              index: true,
              element: <UnitList />,
              loader: async () => {
                return fetch(`${config.apiHost}unit`);
              }
            },
            {
              path: "search/:searchQuery",
              element: <UnitList />,
              loader: async ({params}) => {
                return fetch(`${config.apiHost}units/search?${params.searchQuery}`);
              }
            },
          ]
        },
        {
          path: "my-account",
          children: [
            {
              element: <AccountV2 />,
              index: true,
              loader: async () => {
                if (account) {
                  const address = account.address.slice(2);
                  return fetch(`${config.apiHost}user/${address}`)
                }
                return null;
              },
            },
            {
              element: <Account />,
              path: 'personal-info',
              loader: async () => {
                if (account) {
                  const address = account.address.slice(2);
                  return fetch(`${config.apiHost}user/${address}`)
                }
                return null;
              },
            },
            {
              element: <Bookings />,
              path: "bookings",
              loader: async () => {
                if (account) {
                  const address = account.address.slice(2);
                  return fetch(`${config.apiHost}blocks?account_address=${address}`)
                }
                return null;
              },
            },
            {
              element: <Payments />,
              path: "payments",
              loader: async () => {
                if (account) {
                  const address = account.address.slice(2);
                  return fetch(`${config.apiHost}payments?account_address=${address}`)
                }
                return null;
              },
            },
            {
              element: <BetaTesting />,
              path: "beta-testing",
              loader: async () => {
                if (account) {
                  const address = account.address.slice(2);
                  return fetch(`${config.apiHost}user/${address}`)
                }
                return null;
              },
            },
          ],
        },
        {
          path: "silver-pass",
          element: <Buy />,
        },
        {
          path: "rewards",
          element: <Upgrade />,
          loader: async () => {
            if (account) {
              return getOwnedNestEggs(account.address);
            }
            return null;
          }
        },
        {
          path: "/booking-archive/book/:unitId",
          element: <UnitShow />,
          loader: async ({params}) => {
            return fetch(`${config.apiHost}unit/${params.unitId}`);
          }
        },
      ],
    },
  ]);

  return (
    <RouterProvider router={router} />
  );
}

export default App;
