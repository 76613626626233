import React from 'react';
import placeholder from '../../assets/recoop-silver-pass-v2.png';
import './NestEggCard.css';
import { NestEgg } from '../../types';

interface NestEggCardProps {
  token: NestEgg;
  selected: boolean;
  onClick: (nestEgg: NestEgg, tokenSelection: boolean) => void;
}

const NestEggCard: React.FC<NestEggCardProps> = ({ token, selected, onClick }) => {
  const { name, current_token_data, token_properties } = token;

  const handleCardClick = () => {
    onClick(token, !selected);
  };

  const formatNumberWithCommas = (number: string | number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const pointsValue = getPointsValue(current_token_data, token_properties);
  const pointsValueStr = formatNumberWithCommas(pointsValue);

  function getPointsValue(current_token_data: NestEgg['current_token_data'], token_properties: NestEgg['token_properties']): string {
    const defaultPoints = current_token_data?.default_properties?.points ?? '0';
    const tokenPoints = token_properties?.points ?? null;
    const pointsValue = tokenPoints !== null ? tokenPoints : defaultPoints;
    return pointsValue;
  }

  const image_url = current_token_data.metadata_uri ?? placeholder;

  return (
    <div className="nestegg-card" onClick={handleCardClick}>
      <div className="image">
        <img src={image_url} alt={name} />
        <div className="checkbox">
          <input type="checkbox" className="card-checkbox" checked={selected} readOnly={true} />
        </div>
      </div>
      <div className="details">
        <h5 style={{marginBlockStart: '.5rem', marginBlockEnd: 0 }}>{name}</h5>
        <div style={{display: 'flex',justifyContent: 'space-between', fontWeight: 300, fontSize: '.9rem'}}>
          <p style={{margin: 0}}>Current Balance</p>
          <p style={{margin: 0}}>{pointsValueStr} Points</p>
        </div>

      </div>
    </div>
  );
};

export default NestEggCard;
