import { useWallet } from "@aptos-labs/wallet-adapter-react";
import React, { useState, useEffect } from 'react';
import { AptosClient, CoinClient } from "aptos";
import './UpgradeNestEgg.css';
import { config } from "../../config";
import { NestEgg, UpgradeNestEggValues } from "../../types";
import WalletSelectorWrapper from "../WalletSelectorWrapper/WalletSelectorWrapper";
import { NavLink } from "react-router-dom";

interface UpgradeNestEggProps {
  onSubmit: (event: React.FormEvent<HTMLFormElement>, formValues: UpgradeNestEggValues) => Promise<void>;
  nestEgg: NestEgg | null;
  onUpgradeCompleted: () => void;
}

const client = new AptosClient(config.nodeUrl);
const coinClient = new CoinClient(client);

const UpgradeNestEgg: React.FC<UpgradeNestEggProps> = ({onSubmit, nestEgg, onUpgradeCompleted}) => {
  const { account } = useWallet();
  const [loading, setLoading] = useState<boolean>(true);
  const [balance, setBalance] = useState<bigint>();
  const [formValues, setFormValues] = useState<UpgradeNestEggValues>({
    burnAmount: 0
  });

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        if (account) {
          const bal: bigint = await coinClient.checkBalance(account.address, {coinType: config.rewardCoinType});
          setBalance(bal);
        }
      } catch (error) {
        console.error('Error fetching balance:', error);
        // display an error message to the user
      }
      setLoading(false);
    };
    fetchData();
  }, [account, balance]);

  const refreshBalance = async () => {
    try {
      if (account) {
        const bal: bigint = await coinClient.checkBalance(account.address, {coinType: config.rewardCoinType});
        setBalance(bal);
      }
    } catch (error) {
      console.error('Error fetching balance:', error);
    }
  };

  const resetForm = () => {
    setFormValues({ ...formValues, burnAmount: 0 });
  };

  const formatNumberWithCommas = (number: string | number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const pointsValue = (): string => {
    if (!nestEgg) return "0";
    const defaultPoints = nestEgg.current_token_data?.default_properties?.points ?? '0';
    const tokenPoints = nestEgg.token_properties?.points ?? null;
    const pointsValue = tokenPoints !== null ? tokenPoints : defaultPoints;
    return pointsValue;
  }

  const pointsValueStr = formatNumberWithCommas(pointsValue());
  const pointsValueWithBurn = formatNumberWithCommas(parseInt(pointsValue(), 10) + formValues.burnAmount);

  const handleHalfButton = () => {
    if (balance !== undefined) {
      const halfBalance = Math.floor(Number(balance) / (2 * (10 ** config.rewardCoinDecimals)));
      setFormValues({ ...formValues, burnAmount: halfBalance });
    }
  };

  const handleMaxButton = () => {
    if (balance !== undefined) {
      const maxBalance = Math.floor(Number(balance) / (10 ** config.rewardCoinDecimals));
      setFormValues({ ...formValues, burnAmount: maxBalance });
    }
  };

  const handleSubmitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit(event, formValues).then(() => {
      refreshBalance();
      onUpgradeCompleted();
      resetForm();
    });
  };

  return (
    <>
      <form className="center upgrade-nest-egg" onSubmit={handleSubmitForm}>
        <div style={{border: '1px solid var(--color-dark-o)', borderRadius: 'var(--border-radius)', marginBlockEnd: '.35rem'}}>
          <div style={{borderBlockEnd: '1px solid var(--color-dark-o)', paddingInline: '1rem', paddingBlock: '.25rem'}}>
            <label style={{ fontSize: 'small', fontWeight: 'bold'}}>Coins Available</label>
            {loading ? (
              <p style={{fontSize: 'medium', fontWeight: 300}}>Loading...</p>
            ) : (
              <p style={{fontSize: 'medium', fontWeight: 300}}>
                {account ? (
                  `${balance !== undefined ?
                    Intl.NumberFormat('en-US').format(Number(balance) / (10 ** config.rewardCoinDecimals))
                    : "0"} COOP`
                ) : (
                  'No wallet found'
                )}
              </p>
            )}
          </div>
          <div style={{paddingBlock: '.25rem', paddingInline: '1rem'}}>
            <div className='burn-amount'>
              <label style={{ fontSize: 'small', fontWeight: 'bold'}} htmlFor="amount">Redeem Amount</label>
              {account && <div className="amount-btn">
                <button type="button" onClick={handleHalfButton}>HALF</button>
                <button type="button" onClick={handleMaxButton}>MAX</button>
              </div>}
            </div>

            {account ? (
              (balance === undefined || Number(balance) === 0) ? (
                <p style={{fontSize: 'medium', fontWeight: 300}}><NavLink to={'/booking-archive/my-account/beta-testing'}>Get COOP for testing</NavLink></p>
              ) : (
                <>
                <div style={{display: 'flex', alignItems: 'baseline'}}>
                  <input
                    id="amount"
                    type="number"
                    step='1'
                    min='0'
                    max={(Number(balance) / (10 ** config.rewardCoinDecimals))}
                    style={{ marginBlock: 0}}
                    value={formValues.burnAmount}
                    onChange={(event) => {
                      const value = parseInt(event.target.value, 10);
                      setFormValues({ ...formValues, burnAmount: isNaN(value) ? 0 : value });
                  }} />
                  <p style={{paddingInlineStart: '.5rem', fontSize: 'medium', fontWeight: 300}}>COOP</p>
                </div>

                </>
              )
            ) : (
              <p style={{fontSize: 'medium', fontWeight: 300}}>No wallet found</p>
            )}
          </div>
        </div>
          {formValues.burnAmount > (Number(balance) / (10 ** config.rewardCoinDecimals)) && nestEgg && (
            <p className='center' style={{color: 'red', fontSize: 'small', margin: 0, textAlign: 'center', fontWeight: 400}}>
              Redeem amount is more than available.
            </p>
          )}
            <p
            className='center'
            style={{
              color: 'red',
              fontSize: '.75rem',
              margin: 0,
              textAlign: 'center',
              fontWeight: 400,
              visibility: !account || nestEgg ? 'hidden' : 'visible',
            }}
          >
            Please select a Recoop Reward's Pass.
          </p>
        <div className="upgrade-btn" style={{marginBlockStart: '.35rem'}}>
          {account ? (
            <button
              className={nestEgg && formValues.burnAmount > 0 &&
                formValues.burnAmount <= (Number(balance) / (10 ** config.rewardCoinDecimals)) ? "" : "disabled"} type="submit" disabled={!nestEgg}>Redeem</button>
          ) : (
            <WalletSelectorWrapper />
          )}
        </div>
        <div className='nestegg-details'>
          {nestEgg ? (
            <>
              <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'baseline', marginBlockStart: '1rem', fontSize: '1rem'}}>
                <p style={{margin: 0}}>Previous Balance:</p>
                <p style={{margin: 0}}>{pointsValueStr} Points</p>
              </div>
              <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'baseline', fontSize: '1rem', marginBlockStart: '1rem'}}>
                <p style={{margin: 0, fontWeight: 'bold'}}>New Balance:</p>
                <p style={{margin: 0, fontWeight: 'bold'}}>{pointsValueWithBurn} Points</p>
              </div>
            </>
          ) : (
            <>
              <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'baseline', marginBlockStart: '1rem', fontSize: '1rem'}}>
                <p style={{margin: 0}}>Previous Balance:</p>
                <p style={{margin: 0}}>0 Points</p>
              </div>
              <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'baseline', fontSize: '1rem', marginBlockStart: '1rem'}}>
                <p style={{margin: 0, fontWeight: 'bold'}}>New Balance:</p>
                <p style={{margin: 0, fontWeight: 'bold'}}>0 Points</p>
              </div>
            </>
          )}
        </div>
      </form>
    </>
  )
}

export default UpgradeNestEgg;
