import { NewHeader, Footer, Preloader, CTABanner } from "../components";
import 'react-toastify/dist/ReactToastify.css';
import { Outlet } from 'react-router-dom';
import { useState, useEffect } from 'react'; // Add useCallback import
import { useLocation } from 'react-router-dom';
import { ToastContainer } from "react-toastify";


const NewLayout: React.FC = () => {
  const [imageLoaded, setImageLoaded] = useState<boolean>(false);
  const [imageSrc, setImageSrc] = useState<string>('');
  const location = useLocation();
  const [isLanding, setIsLanding] = useState<boolean>(false);

  useEffect(() => {
    setIsLanding(location.pathname === '/');
  }, [location.pathname]);

  useEffect(() => {
    setImageLoaded(false);  // Set loading to false initially to trigger the preloader during image switch
    const img = new Image();
    if (isLanding) {
      img.src = require("../assets/MainPosterImage.png");
    } else {
      img.src = require("../assets/MainPosterImage-Blur.png");
    }

    img.onload = () => {
      setImageLoaded(true);
      setImageSrc(img.src);
    };
  }, [isLanding]);

  if (!imageLoaded) {
    return <Preloader />; // return the Preloader component while loading
  }

  return (
    <div className="cover" style={{position: 'relative'}}>
      <div className={`bg-image banner`}
      style={{ backgroundImage: `url(${imageSrc})`}}>
        <NewHeader />
        <CTABanner />
      </div>
      <main>
        <Outlet />
      </main>
      <Footer />
      <ToastContainer />
    </div>
  );
}

export default NewLayout;
