import React from "react";
import "./SearchBar0.css";
import { ReactComponent as SearchIcon } from "../../assets/searchIcon.svg";

interface SearchBar0Props {
  handleClick: (sectionNumber: number) => void;
  searchResults: {
    locationQuery: string;
    startDate: Date | null;
    endDate: Date | null;
    guests: number | null;
  };
}

const SearchBar0: React.FC<SearchBar0Props> = ({ handleClick, searchResults }) => {
  const handleSectionClick = (sectionNumber: number) => () => {
    handleClick(sectionNumber);
  };

  return (
    <div className="small-search-bar">
      <div className="location" onClick={handleSectionClick(1)}>
        <span className="title">{searchResults.locationQuery || "Wherever"}</span>
        <span className="divider"></span>
      </div>
      <div className="dates" onClick={handleSectionClick(2)}>
        <span className="title">
          {searchResults.startDate && searchResults.endDate ? `${searchResults.startDate.toLocaleDateString()} - ${searchResults.endDate.toLocaleDateString()}` : "Whenever"}
        </span>
        <span className="divider"></span>
      </div>
      <div className="guests" onClick={handleSectionClick(3)}>
        <span className="description" style={{ display: "block" }}>
        {searchResults.guests ? `${searchResults.guests} Guests` : "Add Guests"}
        </span>
      </div>
      <div className="search" onClick={handleSectionClick(1)}>
        <SearchIcon />
      </div>
    </div>
  );
};

export default SearchBar0;
