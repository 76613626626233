import React from 'react';
import './TenantProfileModal.css';
import AccountModal from '../../Account/AccountModal';
import {ReactComponent as CloseIcon} from '../../../assets/close-icon.svg';

interface TenantProfileModalProps {
  onClose: () => void;
  onProfileCreated: () => void;
}

const TenantProfileModal: React.FC<TenantProfileModalProps> = ({ onClose, onProfileCreated }) => {
  return (
    <div className='tenant-modal' onClick={onClose}>
      <div className='tenant-modal-content' onClick={(e) => e.stopPropagation()}>
        
        <AccountModal onClose={onClose} onProfileCreated={onProfileCreated}/>
      </div>
    </div>
  );
};

export default TenantProfileModal;
