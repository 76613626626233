export const config = {
  apiHost: `${process.env.REACT_APP_API_HOST}/api/v1/`,
  creatorAddress: "0x632676313056fa7f71d8404e3f7aa5075cc3641182e70cd4ac8f2cb538489efb",
  escrowAddress: "0xd859a06a4c813ede12edf41681f549876cc924e9643922bafcc7f5f71c342b22",
  collectionName: "Recoop Silver Passes v1",
  collectionModule: "membership",
  collectionCreate: "create_membership_collection",
  collectionNewToken: "buy_membership_token",
  redeemCoinModule: "redeem_coins",
  redeemRewardCoin: "redeem_reward_coin",
  rewardCoinModule: "reward_coin",
  paymentCoinModule: "payment_coin",
  nodeUrl: "https://fullnode.testnet.aptoslabs.com",
  indexer: "https://indexer-testnet.staging.gcp.aptosdev.com/v1/graphql",
  rewardCoinType: "0x632676313056fa7f71d8404e3f7aa5075cc3641182e70cd4ac8f2cb538489efb::reward_coin::RewardCoin",
  rewardCoinDecimals: 6,
  paymentCoinType: "0x632676313056fa7f71d8404e3f7aa5075cc3641182e70cd4ac8f2cb538489efb::payment_coin::PaymentCoin",
  paymentCoinDecimals: 6,
  bookingManagerAddress: "0x12db84f1be4d4bdb1b4e0a8a1ea5d2e7d5e815af8ea3e1b41095084eb724a009"
};
