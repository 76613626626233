import React, { useCallback, useEffect, useRef } from "react";
import './LocationModal.css';

interface LocationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onQueryChange: (query: string) => void;
  locationList: string[];
  locationInputRef: React.RefObject<HTMLInputElement>;
}

const LocationModal: React.FC<LocationModalProps> = ({ isOpen, onClose, locationList, onQueryChange, locationInputRef }) => {
  const locationModalRef = useRef<HTMLDivElement | null>(null);

  const handleQueryChange = useCallback((query: string) => {
    onQueryChange(query);
  }, [onQueryChange]);

  const handleOutsideClick = useCallback((event: MouseEvent) => {
    if (locationInputRef.current === event.target) return;
    if (locationModalRef.current && !locationModalRef.current.contains(event.target as Node)) {
      onClose();
    }
  }, [onClose, locationInputRef]);

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [handleOutsideClick]);

  const handleLocationClick = (location: string) => {
    handleQueryChange(location);
    onClose();
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className="location-modal" ref={locationModalRef}>
      <h5 style={{paddingInlineStart: '1.5rem', marginBlockStart: '.75rem', marginBlockEnd: 0}}>USA</h5>
      <ul>
        {locationList.map((location, index) => (
          <li key={index} onClick={() => handleLocationClick(location)}>{location}</li>
        ))}
      </ul>
    </div>
  );
};

export default LocationModal;
