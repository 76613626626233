import { NavigationItemProps } from '../../types';
import { Logo } from '../index';
import HamburgerMenu from '../HamburgerMenu/HamburgerMenu';
import "./Header.css";
import { useWallet } from '@aptos-labs/wallet-adapter-react';
import SearchBar0 from '../SearchBar0/SearchBar0';
import SearchBar1 from '../SearchBar1/SearchBar1';
import { useNavigate, useLocation } from 'react-router-dom';
import { useState, useEffect, useRef, useCallback } from 'react'; // Add useCallback import
import { CSSTransition } from 'react-transition-group';


const Header: React.FC = () => {
  const [isClicked, setClicked] = useState<boolean>(false);
  const [focusedSection, setFocusedSection] = useState<number | null>(null);
  const { account } = useWallet();
  const headerWrapperRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [showH5, setShowH5] = useState<boolean>(false);
  const [isHomePage, setIsHomePage] = useState<boolean>(false);

  useEffect(() => {
    setIsHomePage(location.pathname === '/booking-archive');
  }, [location.pathname]);


  const navigation: NavigationItemProps[] = [
    { link: '/booking-archive', text: 'Booking - Home'},
    { link: '/booking-archive/book', text: 'Book a Unit'},
    { link: '/booking-archive/silver-pass', text: 'Get a Silver Pass'},
    { link: '/booking-archive/rewards', text: 'ReCoop Rewards' },
    { link: 'https://docs-archive.recooprentals.com', text: 'About ReCoop' },
    { link: 'https://docs-archive.recooprentals.com/faq', text: 'FAQ' }
  ];

  const [searchValues, setSearchValues] = useState<{
    locationQuery: string;
    startDate: Date | null;
    endDate: Date | null;
    guests: number | null;
  }>({
    locationQuery: "",
    startDate: null,
    endDate: null,
    guests: null,
  });

  const [searchResults, setSearchResults] = useState<{
    locationQuery: string;
    startDate: Date | null;
    endDate: Date | null;
    guests: number | null;
  }>({
    locationQuery: "",
    startDate: null,
    endDate: null,
    guests: null,
  });

  useEffect(() => {
    if (!(location.pathname.startsWith('/booking-archive/book'))) {
      setSearchResults({
        locationQuery: "",
        startDate: null,
        endDate: null,
        guests: null,
      });

      setSearchValues({
        locationQuery: "",
        startDate: null,
        endDate: null,
        guests: null,
      });

      setClicked(false);
    }
  }, [location.pathname]);

  const handleSearchValuesUpdate = (updatedSearchValues: {
    locationQuery?: string;
    startDate?: Date | null;
    endDate?: Date | null;
    guests?: number | null;
  }) => {
    setSearchValues((prevState) => ({
      ...prevState,
      ...updatedSearchValues,
    }));
  };

  const handleSearch = useCallback(async () => {
    const areAllInputsNull = Object.values(searchValues).every(value => value === null || value === '');
    if (areAllInputsNull) {
      setClicked(false);
    } else {
      const queryParams = new URLSearchParams();
      if (searchValues.startDate) {
        queryParams.append('start_date', searchValues.startDate.toISOString().split('T')[0]);
      }
      if (searchValues.endDate) {
        queryParams.append('end_date', searchValues.endDate.toISOString().split('T')[0]);
      }
      if (searchValues.guests) {
        queryParams.append('guests', searchValues.guests.toString());
      }
      if (searchValues.locationQuery && searchValues.locationQuery !== '') {
        queryParams.append('location', searchValues.locationQuery);
      }
      try {
        navigate(`/booking-archive/book/search/${queryParams.toString()}`, { state: { filters: searchValues } });
        setSearchResults(searchValues);
        setClicked(false);
      } catch (error) {
        console.error('Error searching for units:', error);
      }
    }
  }, [searchValues, navigate]);

  const handleClick = (sectionNumber: number) => {
    setClicked(true);
    setFocusedSection(sectionNumber);
  };

  const handleOutsideClick = (event: MouseEvent) => {
    if (headerWrapperRef.current && !headerWrapperRef.current.contains(event.target as Node)) {
      setClicked(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  if (account) {
    navigation.unshift({ link: '/booking-archive/my-account', text: 'My Account' });
  }

  return (
    <>
      <div className={`header-wrapper ${isHomePage ? 'header-home' : ''}`} ref={headerWrapperRef}>
      <div className='header'>
          <Logo isHomePage={isHomePage}/>
          {!isHomePage && <div>
            <CSSTransition
              in={!isClicked && !showH5}
              timeout={300}
              classNames="searchbar0"
              unmountOnExit
              onExited={() => setShowH5(true)}>
              <SearchBar0
                handleClick={handleClick} searchResults={searchResults}/>
            </CSSTransition>
            <CSSTransition
              in={isClicked && showH5}
              timeout={300}
              classNames="searchbar1"
              unmountOnExit
              onExited={() => setShowH5(false)}>
              <h5 style={{marginBlock: 0}}>Explore Our Properties</h5>
            </CSSTransition>
          </div>}
          <HamburgerMenu menuItems={navigation} newSite={false}/>
        </div>
          <CSSTransition
            in={isClicked && showH5}
            timeout={300}
            classNames="searchbar1"
            unmountOnExit>
            <SearchBar1
              autoFocusSection={focusedSection}
              searchValues={searchValues}
              handleSearchValuesUpdate={handleSearchValuesUpdate}
              onSearch={handleSearch}
            />
          </CSSTransition>
      </div>
      {isClicked && <div className="page-overlay"></div>}
    </>
  );
}

export default Header;
