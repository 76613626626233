import { useWallet } from "@aptos-labs/wallet-adapter-react";
import { NavLink, useNavigate } from "react-router-dom";
import './BetaTesting.css';
import { ReactComponent as BetaTestingIcon} from '../../assets/beta.svg';
import { ReactComponent as PaymentIcon } from "../../assets/INVOICE.svg";
import { ReactComponent as RewardIcon } from "../../assets/GIFT.svg";
import { useState, useEffect, useRef } from 'react';
import { AptosClient, CoinClient } from "aptos";
import { config } from "../../config";
import { toast } from 'react-toastify';

type TooltipState = {
  payment: boolean;
  reward: boolean;
};

const client = new AptosClient(config.nodeUrl);
const coinClient = new CoinClient(client);

const BetaTesting: React.FC = () => {
  const { account, signAndSubmitTransaction } = useWallet();
  const [tooltipState, setTooltipState] = useState<TooltipState>({ payment: false, reward: false });
  const paymentMoreInfoRef = useRef<HTMLDivElement | null>(null);
  const rewardMoreInfoRef = useRef<HTMLDivElement | null>(null);
  const paymentTooltipRef = useRef<HTMLDivElement | null>(null);
  const rewardTooltipRef = useRef<HTMLDivElement | null>(null);

  const [rewardCoinLoading, setRewardCoinLoading] = useState<boolean>(true);
  const [rewardCoinBalance, setRewardCoinBalance] = useState<bigint>();

  const [paymentCoinLoading, setPaymentCoinLoading] = useState<boolean>(true);
  const [paymentCoinBalance, setPaymentCoinBalance] = useState<bigint>();

  const navigate = useNavigate();

  useEffect(() => {
    if(!account) {
      navigate('/booking-archive');
    }
  }, [account, navigate]);

  useEffect(() => {
    const fetchData = async () => {
      setRewardCoinLoading(true);
      try {
        if (account) {
          const rewardCoinBal: bigint = await coinClient.checkBalance(account.address, {coinType: config.rewardCoinType});
          setRewardCoinBalance(rewardCoinBal);
        }
      } catch (error) {
        console.error('Error fetching balance:', error);
        // Add toast error, but this will error when coin balance is 0
      }
      setRewardCoinLoading(false);
    };
    fetchData();
  }, [account]);

  const fetchRewardCoinBalance = async (): Promise<bigint> => {
    if (!account) {
      return BigInt(0);
    }
    try {
      const rewardCoinBal: bigint = await coinClient.checkBalance(account.address, {coinType: config.rewardCoinType});
      return rewardCoinBal;
    } catch (error) {
      console.error('Error fetching balance:', error);
      toast.error("Error fetching COOP coin balance.");
      return BigInt(0);
    }
  };

  const mintSmallRewardCoin = async () => {
    if (!account) return [];
    const payload = {
      type: "entry_function_payload",
      function: `${config.creatorAddress}::${config.rewardCoinModule}::mint`,
      type_arguments: [config.rewardCoinType],
      arguments: [
        config.creatorAddress,
        config.collectionName,
        50000000
      ],
    };
    try{
      // sign and submit transaction to chain
      const response = await signAndSubmitTransaction(payload);
      // wait for transaction
      await client.waitForTransaction(response.hash);
      toast.success("Added 50 COOP to your account.");
      // Fetch the updated rewardCoinBalance after the transaction is completed
      const updatedRewardCoinBalance = await fetchRewardCoinBalance();
      // Update the state with the new rewardCoinBalance
      setRewardCoinBalance(updatedRewardCoinBalance);
    } catch (error: any) {
      console.log(error);
      toast.error("Error adding COOP to your account.");
    } finally {
      console.log("Done")
    }
  }

  const mintBigRewardCoin = async () => {
    if (!account) return [];
    const payload = {
      type: "entry_function_payload",
      function: `${config.creatorAddress}::${config.rewardCoinModule}::mint`,
      type_arguments: [config.rewardCoinType],
      arguments: [
        config.creatorAddress,
        config.collectionName,
        100000000000
      ],
    };
    try{
      // sign and submit transaction to chain
      const response = await signAndSubmitTransaction(payload);
      // wait for transaction
      await client.waitForTransaction(response.hash);
      toast.success("Added 100,000 COOP to your account.");
      // Fetch the updated rewardCoinBalance after the transaction is completed
      const updatedRewardCoinBalance = await fetchRewardCoinBalance();
      // Update the state with the new rewardCoinBalance
      setRewardCoinBalance(updatedRewardCoinBalance);
    } catch (error: any) {
      console.log(error);
      toast.error("Error adding COOP to your account.");
    } finally {
      console.log("Done")
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      setPaymentCoinLoading(true);
      try {
        if (account) {
          const paymentCoinBal: bigint = await coinClient.checkBalance(account.address, {coinType: config.paymentCoinType});
          setPaymentCoinBalance(paymentCoinBal);
        }
      } catch (error) {
        console.error('Error fetching balance:', error);
        // display an error message to the user
        // Add toast error, but this will error when coin balance is 0
      }
      setPaymentCoinLoading(false);
    };
    fetchData();
  }, [account]);

  const mintPaymentCoin = async () => {
    if (!account) return [];
    const payload = {
      type: "entry_function_payload",
      function: `${config.creatorAddress}::${config.paymentCoinModule}::mint`,
      type_arguments: [config.paymentCoinType],
      arguments: [
        config.creatorAddress,
        config.collectionName,
        500000000
      ],
    };
    try{
      // sign and submit transaction to chain
      const response = await signAndSubmitTransaction(payload);
      // wait for transaction
      await client.waitForTransaction(response.hash);
      toast.success("Added $500 USD to your account.");
      // Fetch the updated paymentCoinBalance after the transaction is completed
      const updatedPaymentCoinBalance = await fetchPaymentCoinBalance();
      // Update the state with the new paymentCoinBalance
      setPaymentCoinBalance(updatedPaymentCoinBalance);
    } catch (error: any) {
      console.log(error);
      toast.error("Error adding USD to your account.");
    } finally {
      console.log("Done")
    }
  }

  const fetchPaymentCoinBalance = async (): Promise<bigint> => {
    if (!account) {
      return BigInt(0);
    }
    try {
      const paymentCoinBal: bigint = await coinClient.checkBalance(account.address, {coinType: config.paymentCoinType});
      return paymentCoinBal;
    } catch (error) {
      console.error('Error fetching balance:', error);
      // display an error message to the user
      toast.error("Error fetching payment coin balance.");
      return BigInt(0);
    }
  };

  const handleMoreInfoClick = (type: keyof TooltipState) => {
    setTooltipState((prevState) => ({ ...prevState, [type]: !prevState[type] }));
  };

  const handleOutsideClick = (event: MouseEvent) => {
    if (
      paymentTooltipRef.current &&
      !paymentTooltipRef.current.contains(event.target as Node) &&
      paymentMoreInfoRef.current &&
      !paymentMoreInfoRef.current.contains(event.target as Node)
    ) {
      setTooltipState((prevState) => ({ ...prevState, payment: false }));
    }

    if (
      rewardTooltipRef.current &&
      !rewardTooltipRef.current.contains(event.target as Node) &&
      rewardMoreInfoRef.current &&
      !rewardMoreInfoRef.current.contains(event.target as Node)
    ) {
      setTooltipState((prevState) => ({ ...prevState, reward: false }));
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  return (
    <div className="beta-testing">
      <div className="breadcrumb">
        <NavLink to={'/booking-archive/my-account'}>Account</NavLink>
        <p>{'\u203A'}</p>
        <p>Beta Testing</p>
      </div>
      <h2 style={{marginBlockEnd: 0, marginBlockStart: '.5rem'}}>Beta Testing</h2>
      <p style={{margin: 0, fontSize: '.9rem'}}>Address: {`${account?.address.slice(0, 5)}...${account?.address.slice(-5)}`}</p>
      <div style={{backgroundColor: 'white', border: '1px solid var(--color-lightish)', borderRadius: 'var(--border-radius)', marginBlockStart: '2.5rem', display: 'flex', gap: '2rem'}}>
        <div style={{padding: '1.5rem', width: '30%'}}>
          <BetaTestingIcon />
          <h3 style={{margin: 0}}>How can we improve?</h3>
          <p style={{marginBlockEnd: 0, fontSize: '.92rem', marginBlockStart: '.25rem'}}>We value any and all feedback on how we can make ReCoop Rentals meet your needs.</p>
          <a href="mailto:brian.allemeier@gmail.com?subject=ReCoop Rentals Feedback" style={{textDecoration: 'none'}}>
            <button style={{width: '100%', marginBlockStart: '1.5rem', whiteSpace: 'nowrap'}}>Provide Feedback</button>
          </a>
        </div>
        <div className="bg-image"></div>
      </div>
      <h3 style={{fontWeight: '400', marginBlockStart: '2rem', marginBlockEnd: '.5rem'}}>Beta Testing Settings</h3>
      <p style={{fontSize: '1rem', margin: 0}}>These settings are designed to allow you to modify and test parts of the platform without having to use real money or earn COOP.</p>
      <p style={{fontSize: '1rem', margin: 0}}>These settings will not be available when the platform goes live publicy.</p>
      <div style={{display: 'flex', gap: '2rem', marginBlockStart: '1.5rem'}}>
        <div style={{backgroundColor: 'white', border: '1px solid var(--color-lightish)', borderRadius: 'var(--border-radius)', padding: '1.5rem', minWidth: '30%', position: 'relative'}}>
          <PaymentIcon />
          <div className="more-info"  ref={paymentMoreInfoRef} onClick={() => handleMoreInfoClick('payment')}>i</div>
          {tooltipState.payment && (
            <div className="tooltip" ref={paymentTooltipRef}>
              <p>These payment coins represent accepted fiat in our beta version. It is used to pay for bookings and submit security deposits. You can think of of it as USD or USDC.</p>
            </div>
          )}
          <h3 style={{fontWeight: '400', marginBlockEnd: '.5rem', marginBlockStart: 0}}>Payment Coin Balance</h3>
          <div style={{border: '1px solid var(--color-lightish)', borderRadius: 'var(--border-radius)', padding: '1.5rem', textAlign: 'center', fontWeight: 'bold'}}>
            {paymentCoinLoading ? (
              <>Loading...</>
            ) : (
                <>
                  {`$${paymentCoinBalance !== undefined ? (Number(paymentCoinBalance) / (10 ** 6)).toFixed(2) : "0"} USD`}
                </>
            )}
          </div>
          <button style={{width: '100%', marginBlockStart: '1.5rem'}} onClick={mintPaymentCoin}>+ $500 USD</button>

        </div>
        <div style={{backgroundColor: 'white', border: '1px solid var(--color-lightish)', borderRadius: 'var(--border-radius)', padding: '1.5rem', minWidth: '30%', position: 'relative'}}>
          <RewardIcon style={{padding: '.5rem'}} />
          <div className="more-info" ref={rewardMoreInfoRef} onClick={() => handleMoreInfoClick('reward')}>i</div>
          {tooltipState.reward && (
            <div className="tooltip" ref={rewardTooltipRef}>
              <p>These reward coins represent the COOP tokens earned by users during the beta testing phase. They can be used to redeem various rewards and offers.</p>
            </div>
          )}
          <h3 style={{fontWeight: '400', marginBlockEnd: '.5rem', marginBlockStart: 0}}>Reward Coin Balance</h3>
          <div style={{border: '1px solid var(--color-lightish)', borderRadius: 'var(--border-radius)', padding: '1.5rem', textAlign: 'center', fontWeight: 'bold'}}>
            {rewardCoinLoading ? (
              <>Loading...</>
            ) : (
                <>
                {`${rewardCoinBalance !== undefined ? (Number(rewardCoinBalance) / (10 ** 6)) : "0"} COOP`}
                </>
            )}
          </div>
          <div style={{display: 'flex', gap: '1rem'}}>
            <button style={{width: '100%', marginBlockStart: '1.5rem'}} onClick={mintSmallRewardCoin}>+ 50</button>
            <button style={{width: '100%', marginBlockStart: '1.5rem', whiteSpace: 'nowrap'}} onClick={mintBigRewardCoin}>+ 100,000</button>
          </div>

        </div>
      </div>
    </div>
);
}

export default BetaTesting;
