// AdditionalChargesModal.tsx
import React, { useEffect, useRef } from 'react';
import './AdditionalChargesModal.css';
import {ReactComponent as CloseIcon} from '../../../assets/close-icon.svg';

type Charge = {
  name: string;
  amount: number;
};

interface AdditionalChargesModalProps {
  title: string;
  content: Charge[] | string;
  onClose: () => void;
}

const AdditionalChargesModal: React.FC<AdditionalChargesModalProps> = ({ title, content, onClose }) => {
  const modalContentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalContentRef.current && !modalContentRef.current.contains(event.target as Node)) {
        onClose();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  const renderContent = () => {
    if (typeof content === 'string') {
      return <p className='modal-text'>{content}</p>;
    }

    return content.map((charge: Charge) => (
      <div key={charge.name} style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 300, fontSize: 'medium' }}>
        <p>{charge.name}</p>
        <p>${charge.amount}</p>
      </div>
    ));
  };

  return (
    <div className='modal'>
      <div ref={modalContentRef} className='modal-content'>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '3rem' }}>
          <button onClick={onClose}><CloseIcon /></button>
          <h5>{title}</h5>
          <span style={{ minWidth: '1.5rem', minHeight: '1.5rem' }}></span>
        </div>
        {renderContent()}
      </div>
    </div>
  );
};

export default AdditionalChargesModal;
