import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import './CTABanner.css';
import { ReactComponent as XIcon } from '../../assets/x.svg';
import { ReactComponent as DiscordIcon } from '../../assets/discord.svg';
import { ReactComponent as LinkedIcon } from '../../assets/linkedin.svg';
import { toast } from 'react-toastify';

const CTABanner: React.FC = () => {
  const location = useLocation();
  const [isLanding, setIsLanding] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');

  useEffect(() => {
    setIsLanding(location.pathname === '/');
  }, [location.pathname]);

  const handleSubmit = useCallback((event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    fetch('https://us-central1-recoop-rentals.cloudfunctions.net/subscribe', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    })
    .then(response => response.json())
    .then(data => {
      if (data.result === 'success') {
        toast.success('Subscription successful. Welcome to the Roundup!');
        setEmail(''); // Clear the email input field
        console.log('Form submitted successfully');
      } else if (data.result === 'error' && data.msg === 'already subscribed') {
        toast.info('Hey there, silly goose! You’re already subscribed.');
        console.log('User already subscribed');
      } else {
        toast.error('Wow, unknown error, try again :(');
        console.error('Form submission error', data);
      }
    })
    .catch(error => {
      toast.error('Wow, unknown error, try again :(');
      console.error('Form submission error:', error);
    });
  }, [email]);

  return (
    <div className='home-banner'>
      <h1 style={{ color: 'white', fontWeight: 400, marginBlockEnd: 0, lineHeight: 'normal' }}>
        {isLanding ? 'ReCoop Rentals' : 'ReCoop Archives'}
      </h1>
      <p style={{ color: 'white', marginBlock: 0 }}>
        {isLanding ? 'A BLOCKCHAIN REAL ESTATE COMPANY' : 'SIGN UP TO SEE WHAT’S COMING NEXT'}
      </p>
      <form style={{ flexDirection: 'initial' }} className='email-form' onSubmit={handleSubmit}>
        <div className="email-input">
          <input
            type="email"
            placeholder="Enter email address"
            name="EMAIL"
            id="mce-EMAIL"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true">
          <input type="text" name="b_cce4e71466749a6e78de1cb24_2c4bc0fbab" tabIndex={-1} value="" readOnly />
        </div>
        <button type="submit" className="sign-up" value="Subscribe" name="subscribe" id="mc-embedded-subscribe">
          Sign Up
        </button>
      </form>
      <div className='social-icons'>
        <a href="https://twitter.com/recooprentals" className="icon-circle">
          <XIcon />
        </a>
        <a href="https://discord.gg/wVpJhfMmYb" className="icon-circle">
          <DiscordIcon style={{ width: '1.8rem', height: '1.8rem' }} />
        </a>
        <a href="https://www.linkedin.com/company/recoop-rentals" className="icon-circle">
          <LinkedIcon />
        </a>
      </div>
    </div>
  );
}

export default CTABanner;
