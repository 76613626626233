import React from 'react';
import './ResponsiveWrapper.css';

interface ResponsiveWrapperProps {
  children: React.ReactNode;
}

const ResponsiveWrapper: React.FC<ResponsiveWrapperProps> = ({ children }) => {
  return (
    <div className="responsive-wrapper">
      <div className="desktop">{children}</div>
      <div className="mobile">
        <h3>For the best experience, we recommend checking out the ReCoop Rentals booking software on desktop.</h3>
        <p>However, you can still read about our company <a href="https://docs-archive.recooprentals.com/"
         target="_blank"
         rel="noreferrer noopener"
         style={{textDecoration: 'underline solid', textDecorationThickness: '1px'}}>
  here
</a> in our V1 Docs.</p>
      </div>
    </div>
  );
};

export default ResponsiveWrapper;
