import { NavigationItemProps } from '../../types';
import { Logo } from '../index';
import HamburgerMenu from '../HamburgerMenu/HamburgerMenu';
import "./NewHeader.css";
import { useRef } from 'react'; // Add useCallback import

const NewHeader: React.FC = () => {
  const headerWrapperRef = useRef<HTMLDivElement | null>(null);

  const navigation: NavigationItemProps[] = [
    { link: '/', text: 'Home'},
    { link: '/archive', text: 'Archive'},
    { link: 'https://recooprentals.substack.com/', text: 'Substack'},
    { link: 'https://docs.google.com/document/d/1uN80a5qYPzppMJmrdXzNiUxehISU-2EJ9-31yVNFA70/edit?usp=sharing', text: 'Game Outline'},
  ];

  return (
    <>
      <div className={`header-wrapper header-home`} ref={headerWrapperRef}>
      <div className='header'>
          <Logo isHomePage={true}/>
          <HamburgerMenu menuItems={navigation} newSite={true}/>
        </div>
      </div>
    </>
  );
}

export default NewHeader;
