import { NestEggList, UpgradeNestEgg } from "../../components";
import React, { useState } from 'react';
import { useWallet } from "@aptos-labs/wallet-adapter-react";
import { AptosClient } from "aptos";
import { config } from '../../config';
import { NestEgg, UpgradeNestEggValues } from "../../types";
import { getUpdatedNestEgg, getOwnedNestEggs } from "../../actions";
import { useLoaderData } from 'react-router-dom';
import './Upgrade.css';
import OneIcon from '../../assets/icons8-1-48.png';
import TwoIcon from '../../assets/icons8-2-48.png';
import ThreeIcon from '../../assets/icons8-3-48.png';
import { ReactComponent as RewardsIcon} from '../../assets/GIFT.svg';
import { toast } from 'react-toastify';

const client = new AptosClient(config.nodeUrl);

const Upgrade: React.FC = () => {
  const initialNestEggList = useLoaderData() as NestEgg[];
  const [nestEggListState, setNestEggListState] = useState<NestEgg[]>(initialNestEggList);
  const { account, signAndSubmitTransaction } = useWallet();
  const [token, setToken] = useState<NestEgg | null>(null);

  const handleUpgradeNestEggSubmit = async (event: React.FormEvent<HTMLFormElement>, formValues: UpgradeNestEggValues): Promise<void> => {
    if (!account || !token) return Promise.reject(new Error('Account or token not found'));

    return new Promise<void>(async (resolve, reject) => {
      const payload = {
        type: "entry_function_payload",
        function: `${config.creatorAddress}::${config.redeemCoinModule}::${config.redeemRewardCoin}`,
        type_arguments: [`${config.rewardCoinType}`],
        arguments: [
          config.creatorAddress,
          config.collectionName,
          token.name,
          token.property_version,
          formValues.burnAmount
        ],
      };
      try {
        // sign and submit transaction to chain
        const response = await signAndSubmitTransaction(payload);
        // wait for transaction
        await client.waitForTransaction(response.hash);
        if (formValues.burnAmount > 1) {
          toast.success(`${formValues.burnAmount} points banked!`);
        } else {
          toast.success(`${formValues.burnAmount} point banked!`);
        }
        resolve();
      } catch (error: any) {
        console.log(error);
        reject(error);
      } finally {
        console.log("Done");
      }
    });
  };

  const handleSelectedNestEgg = (nestEgg: NestEgg | null) => {
    setToken(nestEgg);
  };

  const handleUpgradeCompleted = async () => {
    if (!account || !token) return;
    // Refresh the NestEggList component
    const upgradedNestEgg = await getUpdatedNestEgg(account.address, token.name);
    if(upgradedNestEgg) {
        setNestEggListState((prevNestEggs) =>
        prevNestEggs.map((nestEgg) => (nestEgg.name === upgradedNestEgg.name ? upgradedNestEgg : nestEgg))
      );
    }
    // Reset NestEgg
    setToken(null);
  };

  const handleMintCompleted = async () => {
    if (!account) return;
    // Refresh the NestEggList component
    try {
      const newPassList = await getOwnedNestEggs(account.address);
      if(newPassList) {
        setNestEggListState(newPassList);
      }
    } catch (error) {
      console.log(console.error);
    }
  };

  return (
    <div className='upgrade'>
        <h2 style={{marginBlockEnd: 0, display: 'flex', alignItems: 'flex-start'}}>ReCoop<span style={{fontSize: 'xx-small', paddingBlockStart: '.5rem', paddingInlineEnd: '.5rem'}}>TM</span> Rewards</h2>
        <p style={{fontSize: 'small', fontWeight: 'bold', marginBlockStart: 0}}>With 100,000 points you can earn a Gold Pass. </p>
        <div style={{backgroundColor: 'white', border: '1px solid var(--color-lightish)', borderRadius: 'var(--border-radius)', marginBlockStart: '1.5rem', display: 'flex', gap: '2rem'}}>
          <div style={{padding: '1.5rem', width: '30%'}}>
            <RewardsIcon />
            <h3 style={{margin: 0}}>Join ReCoop Rewards Today!</h3>
            <p style={{marginBlockEnd: 0, fontSize: '.92rem', marginBlockStart: '.25rem'}}>ReCoop Rewards is our premier program that allows you to be rewarded for engaging with our platform. We’re turning renting into wealth-building.</p>
          </div>
          <div className="bg-image"></div>
        </div>
        <div className='switcher' style={{marginBlockStart: '1rem', alignItems: 'center', flexWrap: 'nowrap'}}>
          <div className="center">
            <h3 style={{margin: 0}}>Redeem Your Points</h3>
            <p style={{marginBlockStart: '.5rem'}}>As a Silver Pass holder, you have the opportunity to redeem COOP coins for points. You get one point for every COOP coin redeemed.
              Points stay with the pass if you ever sell or transfer it.</p>
            <div style={{display: 'flex', alignItems: 'center', paddingInlineStart: '1rem'}}>
              <img style={{width: '2rem', verticalAlign: 'super'}} src={OneIcon} alt="one" />
              <p style={{paddingInline: '.5rem', fontWeight: 300, fontSize: 'medium', marginBlockStart: 0, lineHeight: 'normal'}}>Connect your wallet and select a pass below</p>
            </div>
            <div style={{display: 'flex', alignItems: 'center', paddingInlineStart: '1rem'}}>
              <img style={{width: '2rem', verticalAlign: 'super'}} src={TwoIcon} alt="one" />
              <p style={{paddingInline: '.5rem', fontWeight: 300, fontSize: 'medium', marginBlockStart: 0, lineHeight: 'normal'}}>Choose the amount of COOP to redeem</p>
            </div>
            <div style={{display: 'flex', alignItems: 'center', paddingInlineStart: '1rem'}}>
              <img style={{width: '2rem', verticalAlign: 'super'}} src={ThreeIcon} alt="one" />
              <p style={{paddingInline: '.5rem', fontWeight: 300, fontSize: 'medium', marginBlockStart: 0, lineHeight: 'normal'}}>Hit 'Redeem' and approve the transaction</p>
            </div>
          </div>
          <UpgradeNestEgg
            onSubmit={handleUpgradeNestEggSubmit}
            nestEgg={token}
            onUpgradeCompleted={handleUpgradeCompleted}
          />
        </div>

      {account && <NestEggList
        onSelect={handleSelectedNestEgg}
        nestEggList={nestEggListState}
        tokenSelected={token}
        handleMintCompleted={handleMintCompleted}
      />}
    </div>
  );
}

export default Upgrade;
