import React from 'react';
import ReactDOM from 'react-dom/client';
import './stylesheets/application.css';
import reportWebVitals from './reportWebVitals';
import App from "./App";
import { PetraWallet } from "petra-plugin-wallet-adapter";
import { AptosWalletAdapterProvider } from "@aptos-labs/wallet-adapter-react";
import { FlashMessageProvider } from './components/FlashMessage/FlashMessageContext';
import "@aptos-labs/wallet-adapter-ant-design/dist/index.css";


const wallets = [new PetraWallet()];

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <FlashMessageProvider>
      <AptosWalletAdapterProvider plugins={wallets} autoConnect={true}>
        <App />
      </AptosWalletAdapterProvider>
    </FlashMessageProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
