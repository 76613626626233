import {  Outlet } from 'react-router-dom';
import './Book.css';

const Book: React.FC = () => {
  return (
    <div className='book'>
      <Outlet />
    </div>
  );
}

export default Book;
