import React from 'react';
import { WalletSelector } from '@aptos-labs/wallet-adapter-ant-design';

const WalletSelectorWrapper: React.FC = () => {
  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  return <div onClick={handleClick}><WalletSelector /></div>;
};

export default React.memo(WalletSelectorWrapper);
