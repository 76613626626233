import { useState, useRef, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import './HamburgerMenu.css';
import { NavigationProps } from "../../types";
import WalletSelectorWrapper from '../WalletSelectorWrapper/WalletSelectorWrapper';
import {ReactComponent as UserIcon} from '../../assets/User.svg';

const HamburgerMenu: React.FC<NavigationProps> = ({ menuItems, newSite }) => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      setIsOpen(!isOpen);
    }
  };

  const externalLinkIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
      width="15" height="15"
      viewBox="0 0 24 24">
      <path d="M 3 3 L 3 21 L 21 21 L 21 12 L 19 12 L 19 19 L 5 19 L 5 5 L 12 5 L 12 3 L 3 3 z M 14 3 L 14 5 L 17.585938 5 L 8.2929688 14.292969 L 9.7070312 15.707031 L 19 6.4140625 L 19 10 L 21 10 L 21 3 L 14 3 z"></path>
      </svg>
  );

  const handleClickOutside = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      const hamburgerIcon = document.querySelector('.hamburger-icon');
      const walletSelectorModal = document.querySelector('.ant-modal-content');

      if (hamburgerIcon && hamburgerIcon.contains(event.target as Node)) {
        return;
      }

      // Check if the clicked target is within the WalletSelector modal
      if (walletSelectorModal && (event.target as Element)?.closest('.ant-modal-content')) {
        return;
      }

      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
  <div style={{width: newSite ? 'var(--s2)' : 'var(--s4)' , display: 'flex', justifyContent: 'flex-end'}}>
    <div className={`hamburger-menu ${isOpen ? 'active' : ''}`} style={{paddingBlock: newSite ? '.82rem' : '.2rem'}} role="button" tabIndex={0} onClick={handleClick} onKeyPress={handleKeyPress}>
      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '1rem'}}>
        <div className="hamburger-icon" >
          <div className={`line ${isOpen ? 'line-open' : ''}`} />
          <div className={`line ${isOpen ? 'line-open mid' : ''}`} />
          <div className={`line ${isOpen ? 'line-open' : ''}`} />
        </div>
        {!newSite && <UserIcon />}
      </div>
      {isOpen && (
        <div>
          <div className="menu-list" ref={menuRef}>
            {!newSite && <WalletSelectorWrapper />}
            {menuItems.map((item) => (
              item.text === 'About ReCoop' || item.text === 'FAQ' || item.text === 'Game Outline'|| item.text === 'Substack'? (
                <a
                  href={item.link}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '.5rem',
                    whiteSpace: 'nowrap',
                    ...((item.text === 'About ReCoop' || item.text === 'Substack')
                      ? { borderBlockStart: '1px solid var(--color-lightish)' }
                      : {}),
                  }}
                  className="menu-item gradient-text"
                  target="_blank"
                  rel="noreferrer noopener"
                  key={item.text}
                >
                  {item.text} {externalLinkIcon}
                </a>
              ) : (
                <NavLink
                  to={item.link}
                  className={({ isActive }) =>
                    "menu-item gradient-text" + (isActive ? " active-link" : "")
                  }
                  end={item.link === '/booking-archive'}
                  key={item.link}
                >
                  {item.text}
                </NavLink>
              )
            ))}
          </div>
        </div>
      )}
    </div>
  </div>
  );
}

export default HamburgerMenu;
