import './FlashMessage.css';
import React, { useEffect, useCallback } from 'react';
import { useFlashMessage } from './FlashMessageContext';
import {ReactComponent as CloseIcon} from '../../assets/close-icon.svg';

const FlashMessageBox: React.FC = () => {
  const { flashMessage, setFlashMessage } = useFlashMessage();

  const hideFlashMessage = useCallback(() => {
    setFlashMessage({ message: '', type: '' });
  }, [setFlashMessage]);

  useEffect(() => {
    if (flashMessage.message) {
      const timer = setTimeout(hideFlashMessage, 3000);
      return () => clearTimeout(timer);
    }
  }, [flashMessage, hideFlashMessage]);

  return (
    <div>
      {flashMessage.message && (
        <div className={`flash-message box stack ${flashMessage.type}`}>
          <button type='button' className='close' onClick={hideFlashMessage}>
            <CloseIcon style={{ fill: 'var(--color-dark)', width: '1.5rem' }} />
          </button>
          <p>{flashMessage.message}</p>
        </div>
      )}
    </div>
  );
};

export default FlashMessageBox;
