import { useWallet } from "@aptos-labs/wallet-adapter-react";
import React, { useState, useEffect } from 'react';
import { AptosClient, CoinClient } from "aptos";
import { config } from "../../config";
import { useFlashMessage } from '../../components/FlashMessage/FlashMessageContext';
import { Link } from "react-router-dom";
import './Holdings.css';

const client = new AptosClient(config.nodeUrl);
const coinClient = new CoinClient(client);

const Holdings: React.FC = () => {
  const { account, signAndSubmitTransaction } = useWallet();
  const { setFlashMessage } = useFlashMessage();
  const [rewardCoinLoading, setRewardCoinLoading] = useState<boolean>(true);
  const [paymentCoinLoading, setPaymentCoinLoading] = useState<boolean>(true);
  const [rewardCoinBalance, setRewardCoinBalance] = useState<bigint>();
  const [paymentCoinBalance, setPaymentCoinBalance] = useState<bigint>();

  const displayFlashMessage = (message: string, type: string) => {
    setFlashMessage({ message, type });
  };

  useEffect(() => {
    const fetchData = async () => {
      setPaymentCoinLoading(true);
      try {
        if (account) {
          const paymentCoinBal: bigint = await coinClient.checkBalance(account.address, {coinType: config.paymentCoinType});
          setPaymentCoinBalance(paymentCoinBal);
        }
      } catch (error) {
        console.error('Error fetching balance:', error);
        // display an error message to the user
      }
      setPaymentCoinLoading(false);
    };
    fetchData();
  }, [account]);

  useEffect(() => {
    const fetchData = async () => {
      setRewardCoinLoading(true);
      try {
        if (account) {
          const rewardCoinBal: bigint = await coinClient.checkBalance(account.address, {coinType: config.rewardCoinType});
          setRewardCoinBalance(rewardCoinBal);
        }
      } catch (error) {
        console.error('Error fetching balance:', error);
        // display an error message to the user
      }
      setRewardCoinLoading(false);
    };
    fetchData();
  }, [account]);

  const fetchRewardCoinBalance = async (): Promise<bigint> => {
    if (!account) {
      return BigInt(0);
    }
    try {
      const rewardCoinBal: bigint = await coinClient.checkBalance(account.address, {coinType: config.rewardCoinType});
      return rewardCoinBal;
    } catch (error) {
      console.error('Error fetching balance:', error);
      // display an error message to the user
      return BigInt(0);
    }
  };

  const fetchPaymentCoinBalance = async (): Promise<bigint> => {
    if (!account) {
      return BigInt(0);
    }
    try {
      const paymentCoinBal: bigint = await coinClient.checkBalance(account.address, {coinType: config.paymentCoinType});
      return paymentCoinBal;
    } catch (error) {
      console.error('Error fetching balance:', error);
      // display an error message to the user
      return BigInt(0);
    }
  };

  const mintPaymentCoin = async () => {
    if (!account) return [];
    const payload = {
      type: "entry_function_payload",
      function: `${config.creatorAddress}::${config.paymentCoinModule}::mint`,
      type_arguments: [config.paymentCoinType],
      arguments: [
        config.creatorAddress,
        config.collectionName,
        500000000
      ],
    };
    try{
      // sign and submit transaction to chain
      const response = await signAndSubmitTransaction(payload);
      // wait for transaction
      await client.waitForTransaction(response.hash);
      displayFlashMessage('500 payment coins freshly minted.', 'success');
      // Fetch the updated paymentCoinBalance after the transaction is completed
      const updatedPaymentCoinBalance = await fetchPaymentCoinBalance();
      // Update the state with the new paymentCoinBalance
      setPaymentCoinBalance(updatedPaymentCoinBalance);
    } catch (error: any) {
      console.log(error);
      displayFlashMessage('There was an error.', 'error');
    } finally {
      console.log("Done")
    }
  }

  const mintSmallRewardCoin = async () => {
    if (!account) return [];
    const payload = {
      type: "entry_function_payload",
      function: `${config.creatorAddress}::${config.rewardCoinModule}::mint`,
      type_arguments: [config.rewardCoinType],
      arguments: [
        config.creatorAddress,
        config.collectionName,
        50000000
      ],
    };
    try{
      // sign and submit transaction to chain
      const response = await signAndSubmitTransaction(payload);
      // wait for transaction
      await client.waitForTransaction(response.hash);
      displayFlashMessage('50 reward coins freshly minted.', 'success');
      // Fetch the updated rewardCoinBalance after the transaction is completed
      const updatedRewardCoinBalance = await fetchRewardCoinBalance();
      // Update the state with the new rewardCoinBalance
      setRewardCoinBalance(updatedRewardCoinBalance);
    } catch (error: any) {
      console.log(error);
      displayFlashMessage('There was an error.', 'error');
    } finally {
      console.log("Done")
    }
  }

  const mintBigRewardCoin = async () => {
    if (!account) return [];
    const payload = {
      type: "entry_function_payload",
      function: `${config.creatorAddress}::${config.rewardCoinModule}::mint`,
      type_arguments: [config.rewardCoinType],
      arguments: [
        config.creatorAddress,
        config.collectionName,
        100000000000
      ],
    };
    try{
      // sign and submit transaction to chain
      const response = await signAndSubmitTransaction(payload);
      // wait for transaction
      await client.waitForTransaction(response.hash);
      displayFlashMessage('100,000 reward coins freshly minted.', 'success');
      // Fetch the updated rewardCoinBalance after the transaction is completed
      const updatedRewardCoinBalance = await fetchRewardCoinBalance();
      // Update the state with the new rewardCoinBalance
      setRewardCoinBalance(updatedRewardCoinBalance);
    } catch (error: any) {
      console.log(error);
      displayFlashMessage('There was an error.', 'error');
    } finally {
      console.log("Done")
    }
  }

  const initializeRewardCoin = async () => {
    if (!account) return [];
    const payload = {
      type: "entry_function_payload",
      function: `${config.creatorAddress}::${config.rewardCoinModule}::initialize`,
      type_arguments: [],
      arguments: [
        config.collectionName,
      ],
    };
    try{
      // sign and submit transaction to chain
      const response = await signAndSubmitTransaction(payload);
      // wait for transaction
      await client.waitForTransaction(response.hash);
    } catch (error: any) {
      console.log(error);
    } finally {
      console.log("Done")
    }
  }

  const initializePaymentCoin = async () => {
    if (!account) return [];
    const payload = {
      type: "entry_function_payload",
      function: `${config.creatorAddress}::${config.paymentCoinModule}::initialize`,
      type_arguments: [],
      arguments: [
        config.collectionName,
      ],
    };
    try{
      // sign and submit transaction to chain
      const response = await signAndSubmitTransaction(payload);
      // wait for transaction
      await client.waitForTransaction(response.hash);
    } catch (error: any) {
      console.log(error);
    } finally {
      console.log("Done")
    }
  }

  const createCollection = async () => {
    if (!account) return [];
    const payload = {
      type: "entry_function_payload",
      function: `${config.creatorAddress}::${config.collectionModule}::${config.collectionCreate}`,
      type_arguments: [],
      arguments: [
        "Recoop Silver Passes v1"
      ],
    };
    try{
      // sign and submit transaction to chain
      const response = await signAndSubmitTransaction(payload);
      // wait for transaction
      await client.waitForTransaction(response.hash);
    } catch (error: any) {
      console.log(error);
    } finally {
      console.log("Done")
    }
  }

  const createBookingManager = async () => {
    if (!account) return [];
    const payload = {
      type: "entry_function_payload",
      function: `${config.creatorAddress}::bookings::create_booking_info_resource`,
      type_arguments: [],
      arguments: [],
    };
    try{
      // sign and submit transaction to chain
      const response = await signAndSubmitTransaction(payload);
      // wait for transaction
      await client.waitForTransaction(response.hash);
    } catch (error: any) {
      console.log(error);
    } finally {
      console.log("Done")
    }
  }

  const createManagerEscrow = async () => {
    if (!account) return [];
    const payload = {
      type: "entry_function_payload",
      function: `${config.creatorAddress}::stay_manager::create_manager_escrow`,
      type_arguments: [config.paymentCoinType],
      arguments: [
        config.escrowAddress,
        config.collectionName
      ],
    };
    try{
      // sign and submit transaction to chain
      const response = await signAndSubmitTransaction(payload);
      // wait for transaction
      await client.waitForTransaction(response.hash);
    } catch (error: any) {
      console.log(error);
    } finally {
      console.log("Done")
    }
  }

  return (
    <>
      <div className='coin-holdings center' style={{marginBlockStart: '2rem', textAlign: 'center'}}>
        {account?.address === config.creatorAddress && (
          <div className="box">Membership Collection
            <button style={{marginBlockStart: '1rem', padding:'.5rem'}} className="center" type='button' onClick={createCollection}>Create</button>
          </div>
        )}
        {account?.address === config.bookingManagerAddress && (
        <>
         <div className="box">Manager Booking Resource
            <button style={{marginBlockStart: '1rem', padding:'.5rem'}} className="center" type='button' onClick={createBookingManager}>Create</button>
          </div>
          <div className="box">Manager Escrow Account
            <button style={{marginBlockStart: '1rem', padding:'.5rem'}} className="center" type='button' onClick={createManagerEscrow}>Create</button>
          </div>
        </>
        )}
        <div className="box site-border">Payment Coin Balance:
          {paymentCoinLoading ? (
              <p>Loading...</p>
            ) : (
                <p>
                {`${paymentCoinBalance !== undefined ? (Number(paymentCoinBalance) / (10 ** 6)) : "0"} USD`}</p>
            )}
            {account?.address === config.creatorAddress && (
              <>
                <button style={{marginBlockStart: '1rem'}} className="center" type='button' onClick={initializePaymentCoin}>Initialize Coins</button>
              </>
            )}
          <button style={{marginBlockStart: '1rem'}} className="center" type='button' onClick={mintPaymentCoin}>Add 500</button>
        </div>
        <div className="box site-border">Reward Coin Balance:
          {rewardCoinLoading ? (
              <p>Loading...</p>
            ) : (
                <p>
                {`${rewardCoinBalance !== undefined ? (Number(rewardCoinBalance) / (10 ** 6)) : "0"} COOP`}</p>
            )}
          {account?.address === config.creatorAddress && (
            <>
              <button style={{marginBlockStart: '1rem'}} className="center" type='button' onClick={initializeRewardCoin}>Initialize Coins</button>
            </>
          )}
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <button style={{marginBlockStart: '1rem'}} className="center" type='button' onClick={mintSmallRewardCoin}>Add 50</button>
            <button style={{marginBlockStart: '1rem'}} className="center" type='button' onClick={mintBigRewardCoin}>Add 100,000</button>
          </div>
        </div>
      </div>
      <div className="holdings center" style={{marginBlockStart: '2rem'}}>
        <h2>Coins? What for?</h2>
        <p>These payment coins represent accepted fiat in our beta version. It is used to
          pay for <Link to="/my-account/bookings">bookings</Link> and submit security
          deposits. You can think of of it as USD or USDC.</p>
        <p>Use <Link to="https://docs.recooprentals.com/white-paper/definitions/coop-coin">reward coins</Link> to <Link to="/upgrade">upgrade</Link> the points balance on your Silver Pass.
           Typically, you can only receive them by paying for bookings, but we've provided a way to mint
            them direct to your account. This allows you to test the upgrade functionality and tradability
            of our rewards in beta without making a booking.</p>
      </div>
    </>
  );
  }

  export default Holdings;
