import "./SearchBar1.css";
import { useState, useEffect, useCallback } from "react";
import { ReactComponent as SearchIcon } from '../../assets/searchIcon.svg';
import LocationSearch from "./LocationSearch/LocationSearch";
import { config } from "../../config";
import { getLocationList } from "../../actions";
import DateSearch from "./DateSearch/DateSearch";

interface SearchBar1Props {
  autoFocusSection: number | null;
  searchValues: {
    locationQuery: string;
    startDate: Date | null;
    endDate: Date | null;
    guests: number | null;
  };
  handleSearchValuesUpdate: (updatedSearchValues: {
    locationQuery?: string;
    startDate?: Date | null;
    endDate?: Date | null;
    guests?: number | null;
  }) => void;
  onSearch: () => void;
}

const SearchBar1: React.FC<SearchBar1Props> = ({
  autoFocusSection,
  searchValues,
  handleSearchValuesUpdate,
  onSearch
}) => {
  const [locationList, setLocationList] = useState<string[]>([]);

  useEffect(() => {
    (async () => {
      const locations = await getLocationList();
      setLocationList(locations);
    })();
  }, []);

  const updateSearchValues = useCallback((query: string) => {
    handleSearchValuesUpdate({ locationQuery: query });
  }, [handleSearchValuesUpdate]);

  const handleQueryUpdate = async (query: string) => {
    updateSearchValues(query);

    if (query.trim() !== '') {
      try {
        const response = await fetch(`${config.apiHost}addresses/search?q=${query}`);
        const data = await response.json();
        setLocationList(data.results);
      } catch (error) {
        console.error('Error searching for cities and states:', error);
      }
    } else {
      try {
        const response = await fetch(`${config.apiHost}addresses`);
        const data = await response.json();
        setLocationList(data.results);
      } catch (error) {
        console.error('Error searching for cities and states:', error);
      }
    }
  };

  const handleStartDateUpdate = (startDate: Date | null) => {
    handleSearchValuesUpdate({ startDate });
  };

  const handleEndDateUpdate = (endDate: Date | null) => {
    handleSearchValuesUpdate({ endDate });
  };

  const handleGuestUpdate = (guests: number | null) => {
    handleSearchValuesUpdate({ guests });
  };

  const [state, setState] = useState({
    locationFocused: false,
    locationHovered: false,
    startDateFocused: false,
    startDateHovered: false,
    guestFocused: false,
    guestHovered: false,
    searchClick: false
  });

  const handleLocationMouseEnter = () => {
    setState((prevState) => ({
      ...prevState,
      locationHovered: true,
      startDateHovered: false,
    }));
  };

  const handleStartDateMouseEnter = () => {
    setState((prevState) => ({
      ...prevState,
      locationHovered: false,
      startDateHovered: true,
      endDateHovered: false,
    }));
  };

  const handleGuestMouseEnter = () => {
    setState((prevState) => ({
      ...prevState,
      endDateHovered: false,
      guestHovered: true,
    }));
  };

  const handleMouseLeave = (key: string) => () => {
    setState({ ...state, [key]: false });
  };

  useEffect(() => {
    if (autoFocusSection) {
      switch (autoFocusSection) {
        case 1:
          document.getElementById("search-location")?.focus();
          break;
        case 2:
          document.getElementById("search-start-date")?.focus();
          break;
        case 3:
          document.getElementById("search-guests")?.focus();
          break;
      }
    }
  }, [autoFocusSection]);

  return (
    <div className="home-search-bar">
      <LocationSearch
        onFocus={() => setState((prevState) => ({ ...prevState, locationFocused: true }))}
        onBlur={() => setState((prevState) => ({ ...prevState, locationFocused: false }))}
        onMouseEnter={handleLocationMouseEnter}
        onMouseLeave={handleMouseLeave('locationHovered')}
        onQueryChange={handleQueryUpdate}
        locationList={locationList}
        locationQuery={searchValues.locationQuery}
      />
      <div className="dates">
        <span className='divider'
          style={{
            backgroundColor:
              !state.locationFocused &&
              !state.startDateFocused &&
              !state.guestFocused &&
              (state.locationHovered || state.startDateHovered)
                ? "var(--divider-hover-color)"
                : (state.locationFocused || state.startDateFocused)
                || (state.guestFocused && state.startDateHovered)
                || (state.guestFocused && state.locationHovered)
                ? "var(--divider-focused-color)"
                : "var(--divider-color)",
          }}
        ></span>
        <div className="start"
          onMouseEnter={handleStartDateMouseEnter}
          onMouseLeave={handleMouseLeave('startDateHovered')}
        >
          <DateSearch
            onDatesUpdate={(startDate, endDate) => {
              handleStartDateUpdate(startDate);
              handleEndDateUpdate(endDate);
            }}
            startDate={searchValues.startDate}
            endDate={searchValues.endDate}
            onFocus={() =>
              setState((prevState) => ({ ...prevState, startDateFocused: true }))
            }
            onBlur={() =>
              setState((prevState) => ({ ...prevState, startDateFocused: false }))
            }
          />
        </div>
        <span className='divider'
          style={{
            backgroundColor:
              !state.searchClick &&
              !state.locationFocused &&
              !state.startDateFocused &&
              !state.guestFocused &&
              (state.startDateHovered || state.guestHovered)
                ? "var(--divider-hover-color)"
                : state.startDateFocused || state.guestFocused || state.searchClick
                || (state.locationFocused && state.startDateHovered)
                || (state.locationFocused && state.guestHovered)
                || (state.startDateFocused && state.guestHovered)
                ? "var(--divider-focused-color)"
                : "var(--divider-color)",
          }}
        ></span>
      </div>
      <div className="guests"
        onMouseEnter={handleGuestMouseEnter}
        onMouseLeave={handleMouseLeave('guestHovered')}
      >
        <div className="guest-input">
          <label className="title" htmlFor="search-guests">Who</label>
          <input
            type="number"
            id="search-guests"
            placeholder="Add guests"
            min="1"
            value={searchValues.guests || ''}
            onChange={(e) => handleGuestUpdate(parseInt(e.target.value) || null)}
            onFocus={() => setState((prevState) => ({ ...prevState, guestFocused: true }))}
            onBlur={() => setState((prevState) => ({ ...prevState, guestFocused: false }))}
          />
        </div>
        <button type="submit" className="search" onClick={() => {
            if (onSearch) {
              onSearch();
            }
            setState((prevState) => ({ ...prevState, searchClick: true }));
          }}>
          <SearchIcon />
          Search
        </button>
      </div>
    </div>
  );
};

export default SearchBar1;
