import './UnitShow.css';
import { useLoaderData } from 'react-router-dom';
import { useState, useEffect, useRef } from "react";
import placeholder from '../../assets/casasugar-tiny-houses-wedge.jpg';
import { useWallet } from "@aptos-labs/wallet-adapter-react";
import { DayPickerRangeController, FocusedInputShape, DateRangePicker } from 'react-dates';
import moment from 'moment';
import 'react-dates/lib/css/_datepicker.css';
import { createBlock, createAptosBooking } from '../../actions';
import { UserTenantInfo, NestEgg, BlockAttributes, UnitData } from '../../types';
import { config } from '../../config';
import { getOwnedNestEggs } from '../../actions';
import { ReactComponent as PlusIcon } from '../../assets/plus-svgrepo-com.svg';
import { ReactComponent as MinusIcon } from '../../assets/minus-svgrepo-com.svg';
import { ReactComponent as DownIcon } from '../../assets/down-arrow.svg';
import { ReactComponent as AmentiesIcon } from '../../assets/amenities.svg';
import { ReactComponent as RulesIcon } from '../../assets/rules.svg';
import { ReactComponent as FeesIcon } from '../../assets/fees.svg';
import AdditionalChargesModal from './AdditionalChargesModal/AdditionalChargesModal';
import WalletSelectorWrapper from '../../components/WalletSelectorWrapper/WalletSelectorWrapper';
import TenantProfileModal from './TenantProfileModal/TenantProfileModal';
import { toast } from 'react-toastify';
import { AptosClient } from "aptos";

type Charge = {
  name: string;
  amount: number;
};

const client = new AptosClient(config.nodeUrl);
// Will have to also get staked NestEggs under this account so a user can create bookings
// when they are checked-in / staked in the contract

// Add logic that checks if the NestEgg chosen will conflict with any bookings in the future
const UnitShow: React.FC = () => {
  const unit = useLoaderData() as UnitData;
  const [modalContent, setModalContent] = useState<{title: string, content: Charge[] | string} | null>(null);
  const urlSearchParams = new URLSearchParams(window.location.search);
  const startDateParam = urlSearchParams.get('start_date');
  const endDateParam = urlSearchParams.get('end_date');
  const [guestsParam, setGuestsParam] = useState<number>(
    urlSearchParams.get('guests') === 'null'
    ? 1
    : parseInt(urlSearchParams.get('guests') ?? "1", 10)
  );

  const [isProfileModalOpen, setProfileModalOpen] = useState(false);
  const toggleProfileModal = () => setProfileModalOpen(!isProfileModalOpen);
  const [profileCreated, setProfileCreated] = useState<Boolean>(false);

  const [totalNights, setTotalNights] = useState<number>(0);

  const checkInTime = new Date(unit.unit_detail.check_in_time).toISOString().split('T')[1].slice(0, -8);
  const checkOutTime = new Date(unit.unit_detail.check_out_time).toISOString().split('T')[1].slice(0, -8);

  const getDefaultStartDate = () => {
    const checkInTime = new Date(unit.unit_detail.check_in_time).getUTCHours();
    const today = new Date();
    const adjustedDate = new Date(today.setHours(checkInTime, 0, 0, 0));
    return moment(adjustedDate).utcOffset(0, true);
  };

  const getDefaultEndDate = () => {
    const checkOutTime = new Date(unit.unit_detail.check_out_time).getUTCHours();
    const today = new Date();
    const adjustedDate = new Date(today.setHours(checkOutTime, 0, 0, 0));
    return moment(adjustedDate).add(7, 'days').utcOffset(0, true);
  };

  const [startDate, setStartDate] = useState<moment.Moment | null>(
    startDateParam && startDateParam !== 'null'
      ? moment(startDateParam, 'YYYY-MM-DD')
      : getDefaultStartDate
  );
  const [endDate, setEndDate] = useState<moment.Moment | null>(
    endDateParam && endDateParam !== 'null'
      ? moment(endDateParam, 'YYYY-MM-DD')
      : getDefaultEndDate
  );
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(null);
  const [currentMonth, setCurrentMonth] = useState<moment.Moment>(moment());
  const [visibleMonth, setVisibleMonth] = useState<moment.Moment>(moment());

  const securityDeposit = unit.building?.security_deposit !== undefined
  ? unit.building?.security_deposit
  : unit.unit_detail.security_deposit;

  const { account, signAndSubmitTransaction} = useWallet();
  const [nestEggList, setNestEggList] = useState<NestEgg[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const hasNestEggs = nestEggList.length > 0;
  const [selectedNestEgg, setSelectedNestEgg] = useState<string | null>(null);
  const selectedNestEggObject = nestEggList.find((nestEgg) => nestEgg.name === selectedNestEgg);
  const [userAccount, setUserAccount] = useState<UserTenantInfo | null>(null);

  useEffect(() => {
    const fetchUserData = async () => {
      if (account) {
        setLoading(true);
        try {
          // Fetch user account using the connected wallet address
          const response = await fetch(`${config.apiHost}user/${account.address.slice(2)}`);
          const data = await response.json();
          setUserAccount(data);
        } catch (error: any) {
          console.log(error);
        }
      }
    };
    fetchUserData();
  }, [account]);


  useEffect(() => {
    const fetchData = async () => {
      if (account) {
        setLoading(true);
        try {
          const nestEggs = await getOwnedNestEggs(account.address);
          if (nestEggs) {
            setNestEggList(nestEggs);
          }
        } catch (error: any) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      }
    };
    fetchData();
  }, [account]);

  useEffect(() => {
    if (account && nestEggList.length) {
      setSelectedNestEgg(nestEggList[0].name);
    } else {
      setSelectedNestEgg(null);
    }
  }, [account, nestEggList]);


  useEffect(() => {
    if (!account) {
      setSelectedNestEgg('');
      setNestEggList([]);
    }
  }, [account]);

  useEffect(() => {
    if (startDate && endDate) {
      const totalNights = Math.round((endDate.toDate().getTime() - startDate.toDate().getTime()) / (1000 * 60 * 60 * 24));
      setTotalNights(totalNights);
    }
  }, [startDate, endDate]);

  const handleDatesChange = ({ startDate: newStartDate, endDate: newEndDate }: { startDate: moment.Moment | null, endDate: moment.Moment | null }) => {
    setStartDate(newStartDate?.startOf('day') ?? null);
    setEndDate(newEndDate?.startOf('day') ?? null);

    if (newStartDate && newEndDate) {
      const newTotalNights = newEndDate.diff(newStartDate, 'days');
      setTotalNights(newTotalNights);
    } else {
      setTotalNights(0);
    }
  };

  const isBlocked = (date: moment.Moment) => {
    for (let i = 0; i < unit.blocks.length; i++) {
      const start = moment(unit.blocks[i].start_date);
      const end = moment(unit.blocks[i].end_date);
      if (date.isBetween(start, end, 'day', '[]')) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    setVisibleMonth(currentMonth);
  }, [currentMonth]);

  const hasLateFeeTerm = !unit.property.late_fee_term.end_date
  && new Date(unit.property.late_fee_term.start_date) <= new Date()
  && new Date(unit.property.late_fee_term.end_date ?? new Date()) >= new Date();

  const bookingPrice = unit.min_rent_amount_night * totalNights;
  const additionalCharges = unit.additional_charges.reduce((total, charge) => total + charge.amount, 0);

  const totalSecurityDeposit = (): number => {
    if (securityDeposit) {
      if (totalNights < 28) {
        return 7 * unit.min_rent_amount_night
      }
      return unit.min_rent_amount_night * 28
    }
    return 0
  }

  const petPolicy = unit.building?.pet_policy !== undefined
  ? unit.building?.pet_policy
  : unit.unit_detail.pet_policy;

  const submitReservation = async () => {
    const id = toast.loading("Confirming...");
    if (
      !account ||
      !selectedNestEggObject ||
      !startDate ||
      !endDate ||
      !guestsParam
    )
      return;

    const block: BlockAttributes = {
      guests: guestsParam,
      start_date: startDate.toDate(),
      end_date: endDate.toDate(),
      block_status: 0,
      unit_id: unit.id,
      nest_egg: selectedNestEggObject,
      account_address: account.address.slice(2),
    };
    const result = await createBlock(block);

    if (result.success) {
      toast.update(id, {
        render: "Booking confirmed",
        type: "success",
        isLoading: false,
        autoClose: 5000,
      });
      if (result.block) {
        createAptosBooking(result.block);
      }
    } else {
      toast.update(id, {
        render: result.error || "An error occurred",
        type: "error",
        isLoading: false,
        autoClose: 5000,
      });
      console.error(result.error);
    }
  };


  const handleMinusClick = () => {
    setGuestsParam(prevGuestsParam => Math.max(prevGuestsParam - 1, 1));
  };

  const handlePlusClick = () => {
    setGuestsParam(prevGuestsParam => Math.min(prevGuestsParam + 1, unit.max_tenants));
  };

  const toggleModal = (title: string, content: Charge[] | string) => {
    if (modalContent) {
      setModalContent(null);
    } else {
      setModalContent({ title, content });
    }
  };

  const [isPassModalOpen, setPassModalOpen] = useState(false);
  const togglePassModal = () => setPassModalOpen(true);

  const closePassModalAndSetSelected = (event: React.MouseEvent, nestEggName: string) => {
    event.stopPropagation();
    setSelectedNestEgg(nestEggName);
    setPassModalOpen(false);
  };

  const modalContentRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (modalContentRef.current && !modalContentRef.current.contains(event.target as Node)) {
      setPassModalOpen(false);
    }
  };

  useEffect(() => {
    if (isPassModalOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isPassModalOpen]);

  const getImagePath = (imageNumber: number) => {
    const city = unit.property.address.city.replace(' ', '_');
    const propertyNumber = (unit.id - 1) % 5;
    return `/Test_Properties/${city}/${propertyNumber}/${imageNumber}.png`;
  };

  const onProfileCreated = () => {
    setProfileCreated(true);
  };

  const mintToken = async () => {
    if (!account) return [];
    const payload = {
      type: "entry_function_payload",
      function: `${config.creatorAddress}::${config.collectionModule}::${config.collectionNewToken}`,
      type_arguments: [],
      arguments: [
        config.creatorAddress,
        config.collectionName
      ]
    }

    try {
      // sign and submit transaction to chain
      const response = await signAndSubmitTransaction(payload);
      // wait for transaction
      await client.waitForTransaction(response.hash);
      toast.success(`1x Silver Pass deposited into your account.`);
      handleMintCompleted();
    } catch (error: any) {
      console.log(error);
      toast.error(`There was an error.`);
    } finally {
      console.log("Done")
    }
  }

  const handleMintCompleted = async () => {
    if (!account) return;
    // Refresh the NestEggList component
    try {
      const newPassList = await getOwnedNestEggs(account.address);
      if(newPassList) {
        setNestEggList(newPassList);
      }
    } catch (error) {
      console.log(console.error);
    }
  };

  return (
    <div className='unit-show' style={{maxWidth: '1120px', marginInlineStart: 'auto', marginInlineEnd: 'auto', marginBlockStart: '1rem'}}>
      <h2 className='unit-name'>Unit {unit.unit_number}</h2>
      <p className='unit-show-location'>{unit.property.address.city}, {unit.property.address.state_province}</p>
      <div className="main-image">
        <img id="main-img" src={getImagePath(0)} alt="main"/>
        <div className='other-images hide-on-med-screen'>
          <img src={getImagePath(1)} alt="main"/>
          <img id="second-img" src={getImagePath(2)} alt="main"/>
          <img src={getImagePath(3)} alt="main"/>
          <img id="fourth-img" src={getImagePath(4)} alt="main"/>
        </div>
      </div>
      <div className='switcher'>
        <div className='unit-details'>
          <div className='config'>
            <p>{`${unit.max_tenants} guests`}</p>
            <p>{'\u2022'}</p>
            <p>{`${unit.num_bedrooms} beds`}</p>
            <p>{'\u2022'}</p>
            <p>{`${unit.num_bathrooms} baths`}</p>
          </div>
          <div className='overview'>
            <h2 style={{borderBlockEnd: '2px solid var(--color-lightish)'}}>Overview</h2>
            <div style={{display: 'flex', marginBlockEnd: '1rem'}}>
              <div>
                <AmentiesIcon />
              </div>
              <div style={{paddingInlineStart: '1rem'}}>
                <h4>Amenities</h4>
                <div>
                  <p><span>Unit:</span> {unit.unit_detail.amenities}</p>
                  {unit.building && (
                    <div>
                      <p><span>Building:</span> {unit.building.amenities}</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div style={{display: 'flex', marginBlockEnd: '1rem'}}>
              <RulesIcon />
              <div style={{marginInlineStart: '1rem'}}>
                <h4>Rules</h4>
                <p><span>Pet Policy:</span> {petPolicy}</p>
                <p><span>Minimum Nights:</span> {unit.min_contract_nights}</p>
                <p><span>Check-in:</span> {checkInTime}</p>
                <p><span>Check-out:</span> {checkOutTime}</p>
              </div>
            </div>
            {hasLateFeeTerm && (
              <div style={{display: 'flex'}}>
                <FeesIcon />
                <div style={{marginInlineStart: '1rem'}}>
                  <h4>Fees</h4>
                  <p><span>Late Fee Terms for Payments:</span></p>
                  <ul>
                    <li>Every {unit.property.late_fee_term.reward_reduction_interval} days late, {unit.property.late_fee_term.reward_reduction_percentage/100}% of max reward is lost.</li>
                    <li>After {unit.property.late_fee_term.late_fee_start_interval} days late, a late fee of {unit.property.late_fee_term.late_fee_percentage}% of rent is included.</li>
                  </ul>
                </div>
              </div>
            )}
          </div>
          <div className='hide-on-small-screen' style={{display: 'none'}}>
            <h2>Availability</h2>
            <DayPickerRangeController
              startDate={startDate}
              endDate={endDate}
              onDatesChange={handleDatesChange}
              focusedInput={focusedInput}
              onFocusChange={(newFocusedInput: FocusedInputShape | null) => setFocusedInput(newFocusedInput)}
              hideKeyboardShortcutsPanel
              numberOfMonths={2}
              isDayBlocked={isBlocked}
              initialVisibleMonth={() => visibleMonth} // pass a function
            />
      </div>
        </div>
        <div className='unit-payment box center'>
          <h2 style={{marginBlockEnd: '1rem'}}>${unit.min_rent_amount_night} <span style={{fontSize: 'medium', fontWeight: '400'}}>night</span></h2>
          <div style={{border: '1px solid var(--color-dark-o)', borderRadius: '1rem', marginBlockEnd: '1.5rem'}}>
            <div className='date-pick' style={{maxWidth: 'max-content'}}>
              <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr', paddingBlockStart: '.25rem', textAlign: 'left', gap: '1.25rem'}}>
                <div style={{ fontSize: 'small', fontWeight: 'bold', paddingInlineStart: '1rem'}}>CHECK-IN</div>
                <div style={{fontSize: 'small', fontWeight: 'bold'}}>CHECK-OUT</div>
              </div>
              <DateRangePicker
                startDate={startDate}
                endDate={endDate}
                onDatesChange={handleDatesChange}
                focusedInput={focusedInput}
                onFocusChange={(newFocusedInput: FocusedInputShape | null) => setFocusedInput(newFocusedInput)}
                startDateId="startDate"
                endDateId="endDate"
                isDayBlocked={isBlocked}
              />
            </div>
            <div style={{paddingInline: '1rem',borderBlockStart: '1px solid var(--color-dark-o)', paddingBlock: '.25rem'}}>
              <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr', alignItems: 'center', gap: '1.5rem'}}>
                <div style={{display: 'flex', gap: '1rem'}}>
                  <MinusIcon
                    onClick={guestsParam > 1 ? handleMinusClick : undefined}
                    style={{ opacity: guestsParam === 1 ? 0.5 : 1, cursor: 'pointer' }}/>
                  <PlusIcon
                    onClick={guestsParam < unit.max_tenants ? handlePlusClick : undefined}
                    style={{ opacity: guestsParam === unit.max_tenants ? 0.5 : 1, cursor: 'pointer' }}/>
                </div>
                <div>
                  <div style={{ fontSize: 'small', fontWeight: 'bold'}}>GUESTS</div>
                  <div style={{fontSize: 'medium', fontWeight: 300}}>{guestsParam} guest{guestsParam > 1 ? "s" : ""}</div>
                </div>
              </div>
            </div>
            <div style={{paddingInline: '1rem',borderBlockStart: '1px solid var(--color-dark-o)', paddingBlock: '.25rem', cursor: 'pointer', position: 'relative' }}
              onClick={account && hasNestEggs ? togglePassModal : undefined}>
              <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr', alignItems: 'center', gap: '1.5rem'}}>
                <div style={{display: 'flex'}}>
                  <DownIcon
                    style={{ opacity: account && hasNestEggs ? 1 : 0.5}}/>
                </div>
                <div>
                <div style={{ fontSize: 'small', fontWeight: 'bold'}}>PASS</div>
                  <div style={{fontSize: 'medium', fontWeight: 300}}>
                    {!account ? 'No wallet found'
                      : hasNestEggs
                      ? selectedNestEgg
                      : 'No passes found'}</div>
                </div>
              </div>
              {isPassModalOpen && (
              <div ref={modalContentRef} className="pass-modal">
                <div className="pass-modal-content">
                  <h5 style={{marginBlockEnd: '.5rem'}}>Select a Pass</h5>
                  <ul style={{paddingInlineStart: 0, margin: 0}}>
                    {nestEggList.map((nestEgg) => (
                      <li style={{listStyleType: 'none'}} key={nestEgg.name}>
                        <button
                          onClick={(event) => closePassModalAndSetSelected(event, nestEgg.name)}
                          style={{color: 'var(--color-dark)'}}
                        >
                          {nestEgg.name}
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
            </div>

          </div>
          {account ? (
            (userAccount?.user && userAccount?.tenant) || profileCreated ? (
              hasNestEggs ? (
                <button
                className={hasNestEggs && selectedNestEgg && startDate && endDate && guestsParam ? "" : "disabled"}
                type='button'
                onClick={submitReservation}
                >Reserve</button>
                ) : (
                  <button onClick={mintToken}>Free Silver Pass</button>
                )
            ) : (
              <button onClick={toggleProfileModal}>Create Profile to Reserve</button>
            )
          ): (
            <WalletSelectorWrapper />
          )}
          <p style={{textAlign: 'center', fontSize: 'small', fontWeight: 300}}>You won't be charged yet</p>
          <div className='line-item'>
            <p className='item'>${unit.min_rent_amount_night} x {totalNights} nights</p>
            <p className='item'>${bookingPrice}</p>
          </div>
          <div className='line-item' style={{ position: 'relative' }}>
            <div onClick={() => toggleModal('Additional Charges', unit.additional_charges)} className='item more-details'>
                Additional charges
            </div>
            <p className='item'>${additionalCharges}</p>
          </div>
          <div className='line-item'>
            <div
              onClick={() => toggleModal('Security Deposit', 'If required, security deposit is paid upon check-in. Remaining deposit is returned after staff assesses unit, post check-out.')}
              className='item more-details'>Security deposit
            </div>
            <p className='item'>${totalSecurityDeposit()}</p>
          </div>

          {modalContent && <AdditionalChargesModal title={modalContent.title} content={modalContent.content} onClose={() => setModalContent(null)} />}
          <div className='line-item line-border'>
            <p className='item last'>Total before taxes</p>
            <p className='item last'>${bookingPrice + additionalCharges + totalSecurityDeposit()}</p>
          </div>
          <div className='line-item'>
            <p
              className='item last more-details'
              onClick={() => toggleModal('Estimated Rewards', 'This is the calculated maximum possible rewards for this booking. Realized amount is subject to on-time payments.')}
              >Estimated rewards</p>
            <p className='item last'>{unit.max_coin_reward_night * totalNights} COOP</p>
          </div>
        </div>
      </div>
      {isProfileModalOpen && <TenantProfileModal onClose={toggleProfileModal}  onProfileCreated={onProfileCreated}/>}
    </div>
  );
}

export default UnitShow;
