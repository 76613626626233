import { useLoaderData, NavLink, useNavigate } from "react-router-dom";
import './Bookings.css';
import { BlockInfo, NestEgg, Unit, PaymentData } from "../../types";
import { useState, useEffect } from "react";
import { useWallet } from "@aptos-labs/wallet-adapter-react";
import { config } from "../../config";
import { getUpdatedNestEgg, getStakedTokens, updateBlock, createPayment } from "../../actions";
import { AptosClient } from "aptos";
import { ReactComponent as BookingsIcon} from '../../assets/LOCATION.svg';
import { toast } from 'react-toastify';

const client = new AptosClient(config.nodeUrl);

const Bookings: React.FC = () => {
  const navigate = useNavigate();
  const initialBlocks = useLoaderData() as BlockInfo[] | { error: string };
  const [blocks, setBlocks] = useState(initialBlocks);
  const { account, signAndSubmitTransaction } = useWallet();
  const [stakedTokens, setStakedTokens] = useState<NestEgg[] | null>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if(!account) {
      navigate('/booking-archive');
    }
  }, [account, navigate]);

  const currentBookings = Array.isArray(blocks) ? blocks.filter(block => (block as BlockInfo).block_status === 'confirmed' || (block as BlockInfo).block_status === 'checked_in') : [];
  const pastBookings = Array.isArray(blocks) ? blocks.filter(block => (block as BlockInfo).block_status === 'expired') : [];

  currentBookings.sort((a, b) => new Date(a.start_date).getTime() - new Date(b.start_date).getTime());
  pastBookings.sort((a, b) => new Date(a.start_date).getTime() - new Date(b.start_date).getTime());

  const redirectToBook = () => {
    navigate('/booking-archive/book');
  };

  useEffect(() => {
    const fetchData = async () => {
      if (account) {
        setLoading(true);
        try {
          const tokens_staked = await getStakedTokens(account.address);
          setStakedTokens(tokens_staked);
        } catch (error: any) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      }
    };
    fetchData();
  }, [account]);

  const handleCheckIn = async (block: BlockInfo) => {
    if (!account) return;

    // Get token current property version
    const membership = await getUpdatedNestEgg(account.address, block.membership.tkn_name);

    if (!membership){
      console.log('You no longer own this Pass. Please update the booking before checking in.');
      return;
    }

    const payload = {
      type: "entry_function_payload",
      function: `${config.creatorAddress}::stay_manager::check_in`,
      type_arguments: [config.paymentCoinType],
      arguments: [
        block.booking_identifier,
        config.bookingManagerAddress,
        config.escrowAddress,
        config.collectionName,
        block.membership.tkn_name,
        membership.property_version
      ],
    };
    try{
      // sign and submit transaction to chain
      const response = await signAndSubmitTransaction(payload);
      // wait for transaction
      await client.waitForTransaction(response.hash);

      // Send update to rails API
      const updatedBlock = {
        ...block,
        block_status: 'checked_in',
        fully_paid: block.block_type === 'short_term' ? true : false,
      };

      // Call API to update block
      const updateResponse = await updateBlock(updatedBlock);
      if (!updateResponse.success) {
        // Handle API error
        console.error(updateResponse.error);
      } else {
        if (Array.isArray(blocks)) {
          const updatedBlocks = blocks.map(b =>
            (b as BlockInfo).id === updatedBlock.id ? updatedBlock : b
          );

          // Set the state to the new array
          setBlocks(updatedBlocks);
        }

        const paymentData: PaymentData = {
          block_id: block.id,
          partial_payment: block.block_type === 'short_term' ? false : true,
          payment_txn: response.hash
        };

        createPayment(paymentData).then(paymentResponse => {
          if (paymentResponse.success) {
            console.log('Payment created:', paymentResponse.payment);
          } else {
            console.error('Error creating payment:', paymentResponse.errors);
          }
        }).catch(error => {
          console.error('Error creating payment:', error);
        });

        toast.success("Check-in success");
      }
    } catch (error: any) {
      console.log(error);
      toast.error("An error occurred during check-in");
    } finally {
      console.log("Done")
    }
  }

  const handleCheckout = async (block: BlockInfo) => {
    if (!account || !Array.isArray(stakedTokens)) return;

    // Find the NestEgg object with the matching tkn_name
    const matchingToken = stakedTokens.find(token => token.name === block.membership.tkn_name);

    if (!matchingToken) {
      console.log('No matching NestEgg found');
      return;
    }

    const membership = await getUpdatedNestEgg(matchingToken.owner_address, block.membership.tkn_name);

    if (!membership){
      console.log('There was an error confirming the ownership of this Pass');
      return;
    }

    const payload = {
      type: "entry_function_payload",
      function: `${config.creatorAddress}::stay_manager::check_out`,
      type_arguments: [config.paymentCoinType],
      arguments: [
        block.booking_identifier,
        config.bookingManagerAddress,
        config.escrowAddress,
        config.collectionName,
        block.membership.tkn_name,
        membership.property_version
      ],
    };
    try{
      // sign and submit transaction to chain
      const response = await signAndSubmitTransaction(payload);
      // wait for transaction
      await client.waitForTransaction(response.hash);

      // Send update to rails API
      const updatedBlock = {
        ...block,
        block_status: 'expired'
      };

      // Call API to update block
      const updateResponse = await updateBlock(updatedBlock);
      if (!updateResponse.success) {
        // Handle API error
        console.error(updateResponse.error);
        toast.error("An error occurred while updating the booking");
      } else {
        if (Array.isArray(blocks)) {
          const updatedBlocks = blocks.map(b =>
            (b as BlockInfo).id === updatedBlock.id ? updatedBlock : b
          );

          // Set the state to the new array
          setBlocks(updatedBlocks);
        }

        toast.success("Check-out success");
      }
    } catch (error: any) {
      console.log(error);
      toast.error("An error occurred during check-out");
    } finally {
      console.log("Done")
    }
  }

  const getImagePath = (unit_id: number, location: string) => {
    const city = location.split(',')[0].replace(' ', '_');
    const propertyNumber = (unit_id - 1) % 5;
    const imageNumber = '0'; // You can replace this with the desired image number
    return `/Test_Properties/${city}/${propertyNumber}/${imageNumber}.png`;
  };

  function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <div className="bookings">
      {/* What happens when the token used for the booking has been transferred accounts? Void booking?
       Or needs to be back in tenants account by booking date? */}
      <div className="breadcrumb">
        <NavLink to={'/booking-archive/my-account'}>Account</NavLink>
        <p>{'\u203A'}</p>
        <p>Bookings</p>
      </div>
      <h2 style={{marginBlockEnd: 0, marginBlockStart: '.5rem'}}>Bookings</h2>
      <p style={{margin: 0, fontSize: '.9rem'}}>Address: {`${account?.address.slice(0, 5)}...${account?.address.slice(-5)}`}</p>
      <div style={{backgroundColor: 'white', border: '1px solid var(--color-lightish)', borderRadius: 'var(--border-radius)', marginBlockStart: '2.5rem', display: 'flex', gap: '2rem'}}>
        <div style={{padding: '1.5rem', width: '30%'}}>
          <BookingsIcon />
          <h3 style={{margin: 0}}>No bookings yet?</h3>
          <p style={{marginBlockEnd: 0, fontSize: '.92rem', marginBlockStart: '.25rem'}}>Time to dust off your bags and start planning your next adventure.</p>
          <button onClick={redirectToBook} style={{width: '100%', marginBlockStart: '1.5rem', whiteSpace: 'nowrap'}}>Start Searching</button>
        </div>
        <div className="bg-image"></div>
      </div>
      {currentBookings.length > 0 && (
        <>
          <h3 style={{fontWeight: '400', marginBlockStart: '1.5rem', marginBlockEnd:0}}>Current Bookings</h3>
          <div className="reel" style={{paddingBlockEnd: '1rem', borderBlockEnd: '1px solid var(--color-lightish)'}}>
          {blocks !== null && !("error" in blocks) && currentBookings.map((block) => (
            <div key={block.id} >
              {/* Add an if conditional that checks if the token has been staked
              If it is not yet staked, 'Check-in', if it is staked for this booking, 'Check-out'*/}
              <div className='current' style={{display: 'flex', borderRadius: 'var(--border-radius)', backgroundColor: 'white', border: '1px solid var(--color-lightish)'}}>
                <img src={getImagePath(block.unit.location.id, block.unit.location.property.address)} alt="main-unit" />
                <div style={{padding: '1rem'}}>
                  <h5 style={{whiteSpace: 'nowrap', margin: 0}}>{block.unit.location.property.address}</h5>
                  <p style={{whiteSpace: 'nowrap', fontSize: '.9rem', fontWeight: '300', margin: 0}}>{new Date(block.start_date).toLocaleDateString("en-US", {
                    month: "short",
                    day: "numeric",
                    year: "2-digit",
                  })} - {new Date(block.end_date).toLocaleDateString("en-US", {
                    month: "short",
                    day: "numeric",
                    year: "2-digit",
                  })}</p>
                  <div style={{display: 'flex', alignItems: 'baseline', gap: '.5rem'}}>
                    <h5 style={{whiteSpace: 'nowrap', marginBlockStart: '.75rem', marginBlockEnd: 0}}>Digital Key:</h5>
                    <p style={{whiteSpace: 'nowrap', fontSize: '.9rem', fontWeight: '300', margin: 0}}>{block.membership.tkn_name}</p>
                  </div>
                  <div style={{display: 'flex', alignItems: 'baseline', gap: '.5rem'}}>
                    <h5 style={{whiteSpace: 'nowrap', marginBlockStart: '.75rem', marginBlockEnd: 0}}>Deposit:</h5>
                    <p style={{whiteSpace: 'nowrap', fontSize: '.9rem', fontWeight: '300', margin: 0}}>${block.deposit} USD</p>
                  </div>
                  <div style={{display: 'flex', alignItems: 'baseline', gap: '.5rem'}}>
                    <h5 style={{whiteSpace: 'nowrap', margin: 0}}>Max Rewards Possible:</h5>
                    <p style={{whiteSpace: 'nowrap', fontSize: '.9rem', fontWeight: '300', margin: 0}}>{block.max_rewards} COOP</p>
                  </div>
                  <div style={{display: 'flex', alignItems: 'baseline', gap: '.5rem'}}>
                    <h5 style={{whiteSpace: 'nowrap', marginBlockStart: '.75rem', marginBlockEnd: 0}}>Status:</h5>
                    <p style={{whiteSpace: 'nowrap', fontSize: '.9rem', fontWeight: '300', margin: 0}}>
                      {block.block_status === 'confirmed' ? 'Confirmed' : 'Checked-in'}
                    </p>
                  </div>
                  <button
                    type='button'
                    style={{width: '100%', marginBlockStart: '.5rem'}}
                    onClick={block.block_status === 'confirmed' ? () => handleCheckIn(block) : () => handleCheckout(block)}
                    className={new Date(block.start_date) > new Date() ? "disabled" : "" }
                    disabled={new Date(block.start_date) > new Date()}>
                      {block.block_status === 'confirmed' ? 'Check-in' : 'Check-out'}
                    </button>
                </div>
              </div>
            </div>
          ))}
          </div>
        </>
      )}
      {pastBookings.length > 0 && (
        <>
          <h3 style={{fontWeight: '400', marginBlockStart: '1.5rem', marginBlockEnd:0}}>Past Bookings</h3>
          <div style={{display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(310px, 1fr))', gap: '2.25rem', marginBlockStart: '1rem'}}>
          {blocks !== null && !("error" in blocks) && pastBookings.map((block) => (
            <div key={block.id} >
              {/* Add an if conditional that checks if the token has been staked
              If it is not yet staked, 'Check-in', if it is staked for this booking, 'Check-out'*/}
              <div className='past' style={{display: 'flex'}}>
                <img src={getImagePath(block.unit.location.id, block.unit.location.property.address)} alt="main-unit" />
                <div style={{marginInlineStart: '1rem'}}>
                  <h5 style={{whiteSpace: 'nowrap', margin: 0}}>{block.unit.location.property.address}</h5>
                  <p style={{whiteSpace: 'nowrap', fontSize: '.9rem', fontWeight: '300', margin: 0}}>{new Date(block.start_date).toLocaleDateString("en-US", {
                    month: "short",
                    day: "numeric",
                  })} - {new Date(block.end_date).toLocaleDateString("en-US", {
                    month: "short",
                    day: "numeric",
                  })}</p>
                  <div style={{display: 'flex', alignItems: 'baseline', gap: '.5rem'}}>
                    <h5 style={{whiteSpace: 'nowrap', marginBlockStart: '.75rem', marginBlockEnd: 0}}> Deposit Status:</h5>
                    <p style={{whiteSpace: 'nowrap', fontSize: '.9rem', fontWeight: '300', margin: 0}}>
                    {block.deposit_returned ? 'Returned' : 'Pending'}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
          </div>
        </>
      )}
    </div>
  );
}

export default Bookings;
