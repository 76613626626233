import { toast } from 'react-toastify';
import { useWallet } from "@aptos-labs/wallet-adapter-react";
import { createTenant} from "../../actions";
import { NewTenant } from "../../types";
import { useState } from "react";
import casual from 'casual-browserify';

interface AccountModalProps {
  onClose: () => void;
  onProfileCreated: () => void;
}

const AccountModal: React.FC<AccountModalProps> = ({onClose, onProfileCreated}) => {
  const { account } = useWallet();

  // State for create tenant form
  const [firstName, setFirstName] = useState(casual.first_name);
  const [lastName, setLastName] = useState(casual.last_name);
  const [email, setEmail] = useState(casual.email);
  const [phoneNumber, setPhoneNumber] = useState(casual.phone);

  const handleCreateTenant = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!account) return;
    const addressWithoutPrefix = account.address.slice(2);

    const tenantData: NewTenant = {
      first_name: firstName,
      last_name: lastName,
      email_address: email,
      phone_number: phoneNumber,
      account_address: addressWithoutPrefix,
    };

    const result = await createTenant(tenantData);

    if (result.success) {
      // Reload the page to show the updated tenant data
      toast.success("Profile saved");
      onProfileCreated();
    } else {
      console.error(result.error);
      toast.error(result.error || 'Error saving profile');
    }
    onClose();
  };

  return (
    <div className='profile'>
      <h3 style={{marginBlockStart: 0, marginBlockEnd: '.25rem'}}>Create ReCoop Profile</h3>
      <p style={{margin: 0, fontSize: '.83rem', marginBlockEnd: '.25rem', color: 'red' }}><em>Data randomly filled for testing</em></p>
      <form onSubmit={handleCreateTenant}>
        <label htmlFor="firstName">First name</label>
        <input
          type="text"
          name="firstName"
          id="firstName"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          required
        />
        <div style={{border: '1px solid var(--color-lightish)', marginBlock: '1rem'}}></div>
        <label htmlFor="lastName">Last name</label>
        <input
          type="text"
          name="lastName"
          id="lastName"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          required
        />
        <div style={{border: '1px solid var(--color-lightish)', marginBlock: '1rem'}}></div>
        <label htmlFor="email">Email address</label>
        <p style={{margin: 0, fontSize: '.75rem', opacity: '.5'}}>Use an address you'll always have access to.</p>
        <input
          type="email"
          name="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <div style={{border: '1px solid var(--color-lightish)', marginBlock: '1rem'}}></div>
        <label htmlFor="phone">Phone number</label>
        <p style={{margin: 0, fontSize: '.75rem', opacity: '.5'}}>Contact number (for confirmed guests and ReCoop to get in touch).</p>
        <input
          type="tel"
          id="phone"
          name="phone"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)} />
        <div style={{border: '1px solid var(--color-lightish)', marginBlock: '1rem'}}></div>
        <button type="submit">Save Profile</button>
      </form>
    </div>
  );
};

export default AccountModal;
