import SearchBar1 from '../SearchBar1/SearchBar1';
import { useState, useCallback } from 'react'; // Add useCallback import
import { useNavigate,  } from 'react-router-dom';
import './HomeBanner.css';

const HomeBanner: React.FC = () => {
  const [focusedSection, setFocusedSection] = useState<number | null>(null);
  const navigate = useNavigate();

  const handleClick = (sectionNumber: number) => {
    setFocusedSection(sectionNumber);
  };

  const [searchValues, setSearchValues] = useState<{
    locationQuery: string;
    startDate: Date | null;
    endDate: Date | null;
    guests: number | null;
  }>({
    locationQuery: "",
    startDate: null,
    endDate: null,
    guests: null,
  });

  const [searchResults, setSearchResults] = useState<{
    locationQuery: string;
    startDate: Date | null;
    endDate: Date | null;
    guests: number | null;
  }>({
    locationQuery: "",
    startDate: null,
    endDate: null,
    guests: null,
  });

  const handleSearchValuesUpdate = (updatedSearchValues: {
    locationQuery?: string;
    startDate?: Date | null;
    endDate?: Date | null;
    guests?: number | null;
  }) => {
    setSearchValues((prevState) => ({
      ...prevState,
      ...updatedSearchValues,
    }));
  };

  const handleSearch = useCallback(async () => {
    const areAllInputsNull = Object.values(searchValues).every(value => value === null || value === '');
    if(areAllInputsNull) {
      navigate('/booking-archive/book');
    } else {
      const queryParams = new URLSearchParams();
      if (searchValues.startDate) {
        queryParams.append('start_date', searchValues.startDate.toISOString().split('T')[0]);
      }
      if (searchValues.endDate) {
        queryParams.append('end_date', searchValues.endDate.toISOString().split('T')[0]);
      }
      if (searchValues.guests) {
        queryParams.append('guests', searchValues.guests.toString());
      }
      if (searchValues.locationQuery && searchValues.locationQuery !== '') {
        queryParams.append('location', searchValues.locationQuery);
      }
      try {
        navigate(`/booking-archive/book/search/${queryParams.toString()}`, { state: { filters: searchValues } });
        setSearchResults(searchValues);
      } catch (error) {
        console.error('Error searching for units:', error);
      }
    }
  }, [searchValues, navigate]);

  return (
    <div className='home-banner'>
      <h1 style={{color: 'white', fontWeight: 400, marginBlockEnd: '.75rem'}}>Find your coop</h1>
      <SearchBar1
        autoFocusSection={focusedSection}
        searchValues={searchValues}
        handleSearchValuesUpdate={handleSearchValuesUpdate}
        onSearch={handleSearch}
      />
    </div>
);
}

export default HomeBanner;
