import { useEffect, useRef, useCallback, useState } from 'react';
import LocationModal from './LocationModal';

interface LocationSearchProps {
  onFocus: () => void;
  onBlur: () => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  onQueryChange: (query: string) => void;
  locationList: string[];
  locationQuery: string;
}

const LocationSearch: React.FC<LocationSearchProps> = ({
  onFocus, onBlur, onMouseEnter, onMouseLeave,
  onQueryChange, locationList, locationQuery
}) => {
  const locationListRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [isLocationModalOpen, setLocationModalOpen] = useState<boolean>(false);

  const handleLocationFocus = () => {
    setLocationModalOpen(true);
    onFocus();
  };

  const handleLocationModalClose = () => {
    setLocationModalOpen(false);
  };

  const handleQueryChange = useCallback((query: string) => {
    onQueryChange(query);
  }, [onQueryChange]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value.trim();
    handleQueryChange(query);
  };

  const handleDocumentClick = (event: MouseEvent) => {
    // Close the location list if the click was outside of it
    if (locationListRef.current && !locationListRef.current.contains(event.target as Node)) {
      locationListRef.current.classList.add('hidden');
    }
  };

  useEffect(() => {
    // Add the document click listener when the component mounts
    document.addEventListener('click', handleDocumentClick);
    // Remove the document click listener when the component unmounts
    return () => document.removeEventListener('click', handleDocumentClick);
  }, []);

  return (
    <div className="location"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <label className="title" htmlFor="search-location">Location</label>
      <input
        type="text"
        id="search-location"
        placeholder="Search destinations"
        autoComplete='off'
        onFocus={handleLocationFocus}
        onBlur={onBlur}
        onChange={handleChange}
        value={locationQuery}
        ref={inputRef}
      />
      {locationList.length > 0 &&
      <LocationModal
        isOpen={isLocationModalOpen}
        onClose={handleLocationModalClose}
        onQueryChange={handleQueryChange}
        locationList={locationList}
        locationInputRef={inputRef}
      />}
    </div>
  );
};

export default LocationSearch;
