import './Archive.css';
import introPost from '../../assets/booking-archive.png'
import turningPost from '../../assets/docs-archive.png'
import { Link } from 'react-router-dom';

const Archive: React.FC = () => {

  return (
    <div className='archive'>
      <h2 style={{fontWeight: 400, marginBlockEnd: '.25rem'}}>V1 Sites Circa 2023</h2>
      <p style={{margin: 0, fontSize: '1rem'}}>We have a glorious history. Dig into our V1 docs & booking sites!</p>
      <div className='blog b-grid'>
        <Link to="https://docs-archive.recooprentals.com">
          <img src={turningPost} alt="docs-v1" />
          <h4 style={{marginBlockEnd: 0, marginBlockStart: '.5rem'}}>Docs V1</h4>
          <p style={{fontSize: '1rem'}}>May 13, 2023</p>
        </Link>
        <Link to="/booking-archive">
          <img src={introPost} alt="booking-v1" />
          <h4 style={{marginBlockEnd: 0, marginBlockStart: '.5rem'}}>Booking V1</h4>
          <p style={{fontSize: '1rem'}}>May 13, 2023</p>
        </Link>
      </div>
    </div>
  );
}

export default Archive;
