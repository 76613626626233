import { Link } from 'react-router-dom';
import placeholder from '../../assets/recoop-silver-pass-v2.png';
import './Buy.css';
import { useWallet } from "@aptos-labs/wallet-adapter-react";
import React, { useEffect, useState } from 'react';
import { AptosClient } from "aptos";
import { config } from '../../config';
import { getCollectionSupply } from '../../actions';
import WalletSelectorWrapper from '../../components/WalletSelectorWrapper/WalletSelectorWrapper';
import { toast } from 'react-toastify';
import { ReactComponent as RewardsIcon} from '../../assets/icons8-warranty-100 2.svg';

const client = new AptosClient(config.nodeUrl);

const Buy: React.FC = () => {
  const { account, signAndSubmitTransaction } = useWallet();
  const [collectionSupply, setCollectionSupply] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const supply = await getCollectionSupply();
        setCollectionSupply(supply);
      } catch (error: any) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const refreshSupply = async () => {
    try {
      const supply = await getCollectionSupply();
      setCollectionSupply(supply);
    } catch (error) {
      console.error('Error refreshing collection supply:', error);
    }
  };

  const mintToken = async () => {
    if (!account) return [];
    const payload = {
      type: "entry_function_payload",
      function: `${config.creatorAddress}::${config.collectionModule}::${config.collectionNewToken}`,
      type_arguments: [],
      arguments: [
        config.creatorAddress,
        config.collectionName
      ]
    }

    try {
      // sign and submit transaction to chain
      const response = await signAndSubmitTransaction(payload);
      // wait for transaction
      await client.waitForTransaction(response.hash);
      toast.success(`1x Silver Pass deposited into your account.`);
      await refreshSupply();
    } catch (error: any) {
      console.log(error);
      toast.error(`There was an error.`);
    } finally {
      console.log("Done")
    }
  }

  return (
    <div className='buy'>
      <h2 style={{marginBlockEnd: 0, display: 'flex', alignItems: 'flex-start'}}>Get a Silver Pass</h2>
      <div style={{backgroundColor: 'white', border: '1px solid var(--color-lightish)', borderRadius: 'var(--border-radius)', marginBlockStart: '1.5rem', display: 'flex', gap: '2rem'}}>
          <div style={{padding: '1.5rem', width: '30%'}}>
            <RewardsIcon />
            <h3 style={{margin: 0}}>Earn COOP and redeem it for ReCoop<span style={{fontSize: 'xx-small', paddingBlockStart: '.5rem', paddingInlineEnd: '.25rem', verticalAlign: 'super'}}>TM</span> Rewards</h3>
            <p style={{marginBlockEnd: 0, fontSize: '.92rem', marginBlockStart: '.25rem'}}>ReCoop Rewards is our premier program that allows you to be rewarded for engaging with our platform. We’re turning renting into wealth-building.</p>
          </div>
          <div className="bg-image"></div>
        </div>
      <div className='switcher' style={{display: 'flex', alignItems: 'center', marginBlockStart: '1rem'}}>
        <div className='center'>
          <h2>Upgrade Your Rental Experience</h2>
          <p>A {<Link to="https://docs-archive.recooprentals.com/white-paper/definitions/silver-pass"
          target='_blank' rel="noreferrer noopenner">Silver Pass</Link>} is a revolutionary way to earn rewards for your rental
            payments. Each pass holds a points balance. Every payment you make is qualified
            to earn reward coins ({<Link to="https://docs-archive.recooprentals.com/white-paper/definitions/coop-coin"
            target='_blank' rel="noreferrer noopenner">COOP</Link>}). COOP is used to upgrade your Silver Pass points
            balance.</p>
        </div>
        <div className='buy-details'>
          <img src={placeholder} alt="Silver Pass"/>
          <div className='buy-button' style={{marginBlockStart: '1.5rem', width: '80%'}}>
            {account ? (
              <button style={{width: '100%'}} onClick={mintToken}>Free Silver Pass</button>
            ) : (
              <WalletSelectorWrapper />
            )}
            <p style={{fontSize: 'small', opacity: '.3', textAlign: 'center'}}>CIRCULATING SUPPLY: {collectionSupply}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Buy;
