import React, { useState } from "react";
import { DateRangePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import moment from "moment";

interface DateSearchProps {
  onDatesUpdate: (startDate: Date | null, endDate: Date | null) => void;
  startDate: Date | null;
  endDate: Date | null;
  onFocus?: () => void;
  onBlur?: () => void;
}

const DateSearch: React.FC<DateSearchProps> = ({ onDatesUpdate, startDate, endDate, onFocus, onBlur }) => {
  const [focusedInput, setFocusedInput] = useState<"startDate" | "endDate" | null>(null);

  const handleDatesChange = ({ startDate, endDate }: { startDate: moment.Moment | null; endDate: moment.Moment | null }) => {
    onDatesUpdate(startDate?.toDate() || null, endDate?.toDate() || null);
  };

  const handleFocusChange = (focusedInput: "startDate" | "endDate" | null) => {
    setFocusedInput(focusedInput);
    if (focusedInput) {
      if (onFocus) onFocus();
    } else {
      if (onBlur) onBlur();
    }
  };

  return (
    <div style={{display: 'flex', flexDirection: 'column'}}>
      <div style={{display: "grid", gridTemplateColumns: '1fr 1fr'}}>
        <div className="title" style={{textAlign: 'center', marginInlineStart: '-.6rem'}}>Check-in</div>
        <div className="title" style={{textAlign: 'center', marginInlineEnd: '-.4rem'}}>Check-out</div>
      </div>
      <DateRangePicker
        startDate={startDate ? moment(startDate) : null}
        startDateId="search-start-date"
        startDatePlaceholderText="Add date"
        endDateAriaLabel="Check-out"
        endDatePlaceholderText="Add date"
        endDate={endDate ? moment(endDate) : null}
        endDateId="search-end-date"
        onDatesChange={handleDatesChange}
        focusedInput={focusedInput}
        onFocusChange={handleFocusChange}
        numberOfMonths={1}
        isOutsideRange={(day) => day.isBefore(moment(), "day")}
      />
  </div>
  );
};

export default DateSearch;
