import { useLoaderData, useNavigate, NavLink } from "react-router-dom";
import { createTenant } from "../../actions";
import { useWallet } from "@aptos-labs/wallet-adapter-react";
import { useState, useEffect } from "react";
import { UserTenantInfo, NewTenant } from "../../types";
import './Account.css';
import casual from 'casual-browserify';
import { ReactComponent as InfoIcon} from '../../assets/Icon_monitor, computer protection, shield, antivirus.svg';
import { ReactComponent as DetailsIcon} from '../../assets/Icon_lock, open, lock open.svg';
import { ReactComponent as SharedIcon} from '../../assets/Icon_message encryption, message, lock, secure.svg';
import { toast } from 'react-toastify';


const Account: React.FC = () => {
  const user = useLoaderData() as UserTenantInfo;
  const navigate = useNavigate();
  const { account } = useWallet();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    toast.error('Feature not available yet');
  };

  // State for create tenant form
  const [firstName, setFirstName] = useState(casual.first_name);
  const [lastName, setLastName] = useState(casual.last_name);
  const [email, setEmail] = useState(casual.email);
  const [phoneNumber, setPhoneNumber] = useState(casual.phone);

  useEffect(() => {
    if(!account) {
      navigate('/booking-archive');
    }
  }, [account, navigate]);

  const handleCreateTenant = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!account) return;
    const addressWithoutPrefix = account.address.slice(2);

    const tenantData: NewTenant = {
      first_name: firstName,
      last_name: lastName,
      email_address: email,
      phone_number: phoneNumber,
      account_address: addressWithoutPrefix,
    };

    const result = await createTenant(tenantData);

    if (result.success) {
      // Reload the page to show the updated tenant data
      toast.success("Profile saved");
      navigate('/booking-archive/my-account/personal-info');
    } else {
      console.error(result.error);
      toast.error(result.error || 'Error saving profile');
    }
  };

  function obfuscateEmail(email: string): string {
    const [localPart, domain] = email.split('@');
    if (localPart.length < 2) {
      throw new Error('Email address is not valid.');
    }
    const obfuscatedLocalPart = localPart.charAt(0) + '*'.repeat(3) + localPart.charAt(localPart.length - 1);
    return `${obfuscatedLocalPart}@${domain}`;
  }

  if (user === null) {
    return <div className="box site-border center" style={{marginBlockStart: '5rem', textAlign:'center'}}>Loading...</div>;
  }

    return (
      <>
        <div className="profile">
          <div className="breadcrumb">
            <NavLink to={'/booking-archive/my-account'}>Account</NavLink>
            <p>{'\u203A'}</p>
            <p>Personal Information</p>
          </div>
          <h2 style={{marginBlockEnd: 0, marginBlockStart: '.5rem'}}>Personal Information</h2>
          <p style={{margin: 0, fontSize: '.9rem'}}>Address: {`${account?.address.slice(0, 5)}...${account?.address.slice(-5)}`}</p>
          {!user.user && !user.tenant &&
            <>
              <p style={{margin: 0, fontSize: 'x-small', color: 'red', fontWeight: 300}}>We do not save your address until profile is saved.</p>
              <p style={{marginBlockStart: 0, fontSize: 'x-small', color: 'red', fontWeight: 300}}>Inputs are fake for testing purposes. No need to enter your real information.</p>
            </>
          }
          <div style={{display: "flex", gap: '3rem', marginBlockStart: '2.5rem'}}>
            <form style={{width: '60%'}} onSubmit={handleCreateTenant}>
              <label htmlFor="firstName">First name</label>
              {user.user && user.tenant ? (
                <p className="info-item">{user.tenant.first_name}</p>
              ) : (
                <input
                  type="text"
                  name="firstName"
                  id="firstName"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                />
              )}
              <div style={{border: '1px solid var(--color-lightish)', marginBlock: '1rem'}}></div>
              <label htmlFor="lastName">Last name</label>
              {user.user && user.tenant ? (
                <p className="info-item">{user.tenant.last_name}</p>
              ) : (
                <input
                  type="text"
                  name="lastName"
                  id="lastName"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  required
                />
              )}
              <div style={{border: '1px solid var(--color-lightish)', marginBlock: '1rem'}}></div>
              <label htmlFor="email">Email address</label>
              {user.user && user.tenant ? (
                <p className="info-item">{obfuscateEmail(user.tenant.email_address)}</p>
              ) : (
                <>
                  <p style={{margin: 0, fontSize: '.75rem', opacity: '.5'}}>Use an address you'll always have access to.</p>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </>
              )}
              <div style={{border: '1px solid var(--color-lightish)', marginBlock: '1rem'}}></div>
              <label htmlFor="phone">Phone number</label>

              {user.user && user.tenant ? (
                <>
                  <p style={{fontSize: '.9rem'}}>+1 ***-***-{user.tenant.phone_number.slice(8,12)}</p>
                  <p style={{margin: 0, fontSize: '.75rem', opacity: '.5'}}>Contact number (for confirmed guests and ReCoop to get in touch).</p>
                </>
              ) : (
                <>
                  <p style={{margin: 0, fontSize: '.75rem', opacity: '.5'}}>Contact number (for confirmed guests and ReCoop to get in touch).</p>
                  <input
                    type="tel"
                    id="phone"
                    name="phone"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)} />
                  </>
              )}
              <div style={{border: '1px solid var(--color-lightish)', marginBlock: '1rem'}}></div>
              <p style={{fontSize: '.9rem', margin: 0}}>Government ID</p>
              <p style={{fontSize: '.83rem', marginBlockStart: '.5rem', marginBlockEnd: 0}}>Verifying the identity of our guests helps us to:</p>
              <ul style={{fontSize: '.83rem', margin: 0}}>
                <li>Scan for fraudulent behavior</li>
                <li>Do background checks where permitted under applicable laws</li>
                <li>Understand if a malicious actor has taken control of an account</li>
                <li>Investigate safety and security issues such as assault, robbery or fraudulent activity</li>
              </ul>
              <div style={{display: 'flex', alignItems: 'center', marginBlockStart: '1rem'}}>
                <button
                  style={{
                    backgroundColor: 'var(--color-dark)',
                    backgroundImage: 'none',
                    fontWeight: 300,
                    maxWidth: '12rem',
                    paddingInline: '2rem',
                    height: '2.5rem',
                    margin: 0
                  }}
                  onClick={handleClick}
                >
                  Verify Identity
                </button>
              </div>
              {!user.user && !user.tenant &&
                <>
                  <div style={{border: '1px solid var(--color-lightish)', marginBlock: '1rem'}}></div>
                  <button type="submit">Save Profile</button>
                </>
              }
            </form>
            <div className="sidebar">
              <div>
                <InfoIcon />
                <h3>Why isn't my info shown here?</h3>
                <p>We’re hiding some account details to protect your identity.</p>
              </div>
              <div style={{border: '1px solid var(--color-lightish)', marginBlock: '1rem'}}></div>
              <div>
                <DetailsIcon />
                <h3>Which details can be edited?</h3>
                <p>Details ReCoop uses to verify your identity can’t be changed. Contact info and some personal details can be edited, but we may ask you to verify your identity the next time you book.</p>
              </div>
              <div style={{border: '1px solid var(--color-lightish)', marginBlock: '1rem'}}></div>
              <div>
                <SharedIcon />
                <h3>What info is shared with others?</h3>
                <p>ReCoop only releases contact information for landlords and guests after a reservation is confirmed.</p>
              </div>

            </div>
          </div>
        </div>
      </>
    );
  }

export default Account;
