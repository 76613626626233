import axios from "axios";
import { NewTenant, NewUser, NewBlock , NestEgg, MoveBlock, BlockInfo, PaymentData, PaymentResponse} from "../types";
import { config } from "../config";
import { AptosClient, AptosAccount, BCS, TxnBuilderTypes, HexString } from "aptos";
const {
  AccountAddress,
  EntryFunction,
  TransactionPayloadEntryFunction,
  RawTransaction,
  ChainId,
} = TxnBuilderTypes;

const client = new AptosClient(config.nodeUrl);


// Create Functions

export const createBlock = async (blockData: NewBlock): Promise<{ success: boolean; error?: string; block?: MoveBlock }> => {
  try {
    const response = await axios.post<{ block: MoveBlock }>(`${config.apiHost}block`, blockData);
    return { success: true, block: response.data.block };
  } catch (error) {
    if (axios.isAxiosError(error) && error.response && error.response.data) {
      return { success: false, error: error.response.data.errors[0] };
    } else {
      console.error(error);
      return { success: false, error: 'An unexpected error occurred' };
    }
  }
};

export const createUser = async(userData: NewUser) => {
  try {
    const response = await axios.post<NewUser>(`${config.apiHost}user`, userData);
    return response.data;
  } catch (error) {
    console.error(error);
  }
}

export const createTenant = async (tenantData: NewTenant): Promise<{ success: boolean; error?: string }> => {
  try {
    const response = await axios.post<NewTenant>(`${config.apiHost}tenant`, tenantData);
    return { success: true };
  } catch (error) {
    if (axios.isAxiosError(error) && error.response && error.response.data) {
      return { success: false, error: error.response.data.error };
    } else {
      console.error(error);
      return { success: false, error: 'An unexpected error occurred' };
    }
  }
};

export const createAptosBooking = async (block: MoveBlock) => {

  const privateKey = process.env.REACT_APP_BOOKING_MANAGER_PRIVATE || '';
  const privateKeyBytes = HexString.ensure(privateKey).toUint8Array();
  const aptosAccount = new AptosAccount(privateKeyBytes);

  const cost = block.cost * 10**6;
  const reward = block.max_rewards * 10**6;
  const deposit = block.security_deposit * 10**6;

  const entryFunctionPayload = new TransactionPayloadEntryFunction(
    EntryFunction.natural(
      // Fully qualified module name, `AccountAddress::ModuleName`
      `${config.creatorAddress}::bookings`,
      // Module function
      "create_booking",
      [],
      // Arguments for function `transfer`: receiver account address and amount to transfer
      [
        BCS.bcsSerializeUint64(block.start_date),
        BCS.bcsSerializeUint64(block.end_date),
        BCS.bcsSerializeStr(block.booking_id),
        BCS.bcsSerializeUint64(cost),
        BCS.bcsSerializeUint64(reward),
        BCS.bcsSerializeUint64(deposit),
      ],
    ),
  );

  const [{ sequence_number: sequenceNumber }, chainId] = await Promise.all([
    client.getAccount(aptosAccount.address()),
    client.getChainId(),
  ]);

  const rawTxn = new RawTransaction(
    // Transaction sender account address
    AccountAddress.fromHex(aptosAccount.address()),
    BigInt(sequenceNumber),
    entryFunctionPayload,
    // Max gas unit to spend
    BigInt(2000),
    // Gas price per unit
    BigInt(100),
    // Expiration timestamp. Transaction is discarded if it is not executed within 10 seconds from now.
    BigInt(Math.floor(Date.now() / 1000) + 10),
    new ChainId(chainId),
  );

  const bcsTxn = AptosClient.generateBCSTransaction(aptosAccount, rawTxn);

  const transactionRes = await client.submitSignedBCSTransaction(bcsTxn);

  await client.waitForTransaction(transactionRes.hash);
}

export const createPayment = async (paymentData: PaymentData): Promise<PaymentResponse> => {
  try {
    const response = await axios.post(`${config.apiHost}payments`, paymentData);
    if (response.status === 201) {
      return { success: true, payment: response.data };
    } else {
      return { success: false, errors: ['Unexpected response status'] };
    }
  } catch (error) {
    // handle error
    return { success: false, errors: [(error as Error).message] };
  }
};

// Update functions

export const updateBlock = async (blockData: Partial<BlockInfo>): Promise<{ success: boolean; error?: string; block?: BlockInfo}> => {
  try {
    const response = await axios.patch(`${config.apiHost}block/${blockData.id}`, blockData);
    return { success: true, block: response.data.block };
  } catch (error) {
    if (axios.isAxiosError(error) && error.response && error.response.data) {
      return { success: false, error: error.response.data.errors[0] };
    } else {
      console.error(error);
      return { success: false, error: 'An unexpected error occurred' };
    }
  }
};

// Get functions

export async function getLocationList(): Promise<string[]> {
  try {
    const response = await fetch(`${config.apiHost}addresses`);
    const data = await response.json();
    return data.results;
  } catch (error) {
    console.error("Error getting location list from api")
    return [];
  }
}

export async function getOwnedNestEggs(accountAddress: string): Promise<NestEgg[] | null> {
  try {
    const tokensList = await fetchOwnedNestEggs(accountAddress);
    const nestEggList = buildNestEggList(tokensList);
    return nestEggList;
  } catch (error) {
    console.error("Error getting token list from indexer")
    return null;
  }
}

export async function getUpdatedNestEgg(accountAddress: string, token_name: string): Promise<NestEgg | null> {
  try {
    const tokensList = await fetchNestEggWithName(accountAddress, token_name);
    const nestEggList = buildNestEggList(tokensList);
    return nestEggList[0];
  } catch (error) {
    console.error("Error getting token list from indexer")
    return null;
  }
}

export async function getCollectionSupply(): Promise<number> {
  try {
    const collectionSupply = await fetchCollectionData();
    return collectionSupply;
  } catch (error) {
    console.log(error);
    return 0;
  }
}

export async function getStakedTokens(accountAddress: string): Promise<NestEgg[] | null> {
  const tokens: NestEgg[] = [];
  try {
    const resourceInfo = await queryResource(accountAddress, "BookerEscrowInfo");
    if (!resourceInfo) return null;
    const resourceMap: {[k: string]: any}[] = (resourceInfo as any).data.checked_in.data;
    for (const resource of resourceMap) {
        const stakedTokens = await getOwnedNestEggs(resource.value);
        if (!stakedTokens) continue;
        tokens.push(...stakedTokens); // Using spread operator to add elements to the array
    }
    if (tokens.length > 0) {
      return tokens;
    } else {
      return null;
    }
  } catch (error: any) {
    console.log(error);
    return null;
  }
}

// Graphql
async function fetchCollectionData(): Promise<number> {
  const operations = `
  query currentSupply {
    current_collection_datas(
      where: {creator_address: {_eq: "${config.escrowAddress}"}, collection_name: {_eq: "${config.collectionName}"}}
    ) {
      supply
    }
  }
  `;
  try{
    const response = await fetchGraphQL(operations, "currentSupply", {});
    const supplyData: number = (response as any).data.current_collection_datas[0].supply;
    return supplyData;
  } catch(error: any) {
    console.log(error);
    return 0;
  }
}

async function fetchNestEggWithName(accountAddress: string, token_name: string): Promise<{[k: string]: any}[]> {
  const operations = `
    query currentNestEgg {
      current_token_ownerships(
        where: {owner_address: {_eq: "${accountAddress}"}, creator_address: {_eq: "${config.escrowAddress}"} collection_name: {_eq: "${config.collectionName}"}, name: {_eq: "${token_name}"}, amount: {_gt: "0"}}
      ) {
        name
        property_version
        current_token_data {
          default_properties
          metadata_uri
        }
        token_properties
      }
    }
  `;
  try{
    const response = await fetchGraphQL(operations, "currentNestEgg", {});
    const tokensList = (response as any).data.current_token_ownerships;
    return tokensList;
  } catch(error: any) {
    console.log(error);
    return [{}];
  }
}

async function fetchOwnedNestEggs(accountAddress: string): Promise<{[k: string]: any}[]> {
  const operations = `
    query currentNestEggs {
      current_token_ownerships(
        where: {owner_address: {_eq: "${accountAddress}"}, creator_address: {_eq: "${config.escrowAddress}"} collection_name: {_eq: "${config.collectionName}"}, amount: {_gt: "0"}}
      ) {
        name
        property_version
        current_token_data {
          default_properties
          metadata_uri
        }
        token_properties
        owner_address
      }
    }
  `;
  try{
    const response = await fetchGraphQL(operations, "currentNestEggs", {});
    const tokensList: {[k: string]: any}[] = (response as any).data.current_token_ownerships;
    return tokensList;
  } catch(error: any) {
    console.log(error);
    return [{}];
  }
}

function fetchGraphQL(
  operationsDoc: string,
  operationName: string,
  variables: Record<string, any>
) {
  return fetch( config.indexer, {
    method: 'POST',
    body: JSON.stringify({
      query: operationsDoc,
      variables,
      operationName,
    }),
  }).then(result => result.json());
}

// Helper Functions

function buildNestEggList(tokenList: {[k: string]: any}[]): NestEgg[] {
  const nestEggs: NestEgg[] = [];

  for (const tokenObj of tokenList) {
    const {
      name,
      property_version,
      owner_address,
      current_token_data: {
        default_properties: {
          points
        },
        metadata_uri
      },
      token_properties
    } = tokenObj;

    const newNestEgg: NestEgg = {
      name,
      property_version,
      owner_address,
      current_token_data: {
        default_properties: {
          points
        },
        metadata_uri
      },
      token_properties : {
        points: token_properties?.points
      }
    };
    nestEggs.push(newNestEgg);
  }

  return nestEggs;
}

async function queryResource(accountAddress: string, moduleName: string): Promise<any> {
  try {
    const resourceInfo = await client.getAccountResource(
      accountAddress,
      `${config.creatorAddress}::stay_manager::${moduleName}`
    )
    return resourceInfo;
  } catch(error: any) {
    console.error(`No resource found for
     ${config.creatorAddress}::stay_manager::${moduleName} under ${accountAddress}`);
    return null;
  }
}
