import "./Footer.css";
import { Link } from "react-router-dom";
import { ReactComponent as TwitterIcon} from '../../assets/x.svg';
import { ReactComponent as DiscordIcon} from '../../assets/discord.svg';
import { ReactComponent as LinkedIcon} from '../../assets/linkedin.svg';

const Footer: React.FC = () => {
  return (
      <footer className="copyright">
        <div className="h-footer">
          <div style={{display: 'flex', fontSize: '.83rem', alignItems: 'center'}}>
            <p>{'\u00A9'}2023 ReCoop Rentals, LLC</p>
            <p>{'\u2022'}</p>
            <Link to="https://docs-archive.recooprentals.com/terms-of-service"
              target='_blank' rel="noreferrer noopenner"><p>Terms</p></Link>
            <p>{'\u2022'}</p>
            <Link to="https://docs-archive.recooprentals.com/recoop-privacy"
              target='_blank' rel="noreferrer noopenner"><p>Privacy</p></Link>
            <p>{'\u2022'}</p>
            <a href="https://twitter.com/recooprentals"
              target='_blank' rel="noreferrer noopener" style={{ display: 'flex', alignItems: 'center' }}>
              <TwitterIcon style={{width: '1rem'}}/>
            </a>
            <a href="https://discord.gg/mZUzd85g2Y"
              target='_blank' rel="noreferrer noopener" style={{ display: 'flex', alignItems: 'center', marginInlineStart: '.5rem' }}>
              <DiscordIcon style={{width: '1.25rem'}}/>
            </a>
            <a href="https://www.linkedin.com/company/recoop-rentals"
                target='_blank' rel="noreferrer noopener" style={{ display: 'flex', alignItems: 'center', marginInlineStart: '.5rem'}}>
                <LinkedIcon style={{width: '1rem'}}/>
              </a>
          </div>
          <div style={{display: 'flex', fontSize: '.83rem'}}>
            <p>English (US)</p>
            <p>{'\u2022'}</p>
            <p>$ USD</p>
            <p>{'\u2022'}</p>
            <Link to="https://docs-archive.recooprentals.com"
              target='_blank' rel="noreferrer noopenner"><p>Support & Resources</p></Link>
          </div>
        </div>
        <div className="v-footer">
          <div style={{display: 'flex', flexDirection: 'column', fontSize: '.83rem', alignItems: 'center'}}>
            <div style={{display: 'flex', gap: '1rem', marginBlockEnd: '.5rem'}}>
              <a href="https://twitter.com/recooprentals"
                target='_blank' rel="noreferrer noopener" style={{ display: 'flex', alignItems: 'center' }}>
                <TwitterIcon style={{width: '1.25rem'}}/>
              </a>
              <a href="https://discord.gg/mZUzd85g2Y"
                target='_blank' rel="noreferrer noopener" style={{ display: 'flex', alignItems: 'center' }}>
                <DiscordIcon  style={{width: '1.5rem', height: '1.25rem'}}/>
              </a>
              <a href="https://www.linkedin.com/company/recoop-rentals"
                target='_blank' rel="noreferrer noopener" style={{ display: 'flex', alignItems: 'center'}}>
                <LinkedIcon style={{width: '1.25rem'}} />
              </a>
            </div>
            <Link to="https://docs-archive.recooprentals.com/terms-of-service"
              target='_blank' rel="noreferrer noopenner"><p>Terms</p></Link>
            <Link to="https://docs-archive.recooprentals.com/recoop-privacy"
              target='_blank' rel="noreferrer noopenner"><p>Privacy</p></Link>
            <Link to="https://docs-archive.recooprentals.com"
            target='_blank' rel="noreferrer noopenner"><p>Support & Resources</p></Link>
            <p>{'\u00A9'}2023 ReCoop Rentals, LLC</p>
          </div>
        </div>
      </footer>
  );
};

export default Footer;
