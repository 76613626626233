import React, { createContext, useState, useContext } from 'react';

interface FlashMessage {
  message: string;
  type: string;
}

interface IFlashMessageContext {
  flashMessage: FlashMessage;
  setFlashMessage: React.Dispatch<React.SetStateAction<FlashMessage>>;
}

const FlashMessageContext = createContext<IFlashMessageContext | undefined>(undefined);

export const useFlashMessage = (): IFlashMessageContext => {
  const context = useContext(FlashMessageContext);
  if (!context) {
    throw new Error('useFlashMessage must be used within a FlashMessageProvider');
  }
  return context;
};

interface FlashMessageProviderProps {
  children: React.ReactNode;
}

export const FlashMessageProvider: React.FC<FlashMessageProviderProps> = ({ children }) => {
  const [flashMessage, setFlashMessage] = useState<FlashMessage>({ message: '', type: '' });
  return (
    <FlashMessageContext.Provider value={{ flashMessage, setFlashMessage }}>
      {children}
    </FlashMessageContext.Provider>
  );
};
