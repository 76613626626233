import "./Logo.css";
import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useMediaQuery } from "@material-ui/core";
import LogoIcon from "../../assets/logo192.png";
import LogoWhiteIcon from "../../assets/logo192-white.png";

interface LogoProps {
  isHomePage: boolean;
}

const Logo: React.FC<LogoProps> = ({ isHomePage }) => {
  const isSmallScreen = useMediaQuery("(max-width: 950px)");
  const [fontLoaded, setFontLoaded] = useState(false);

  useEffect(() => {
    const font = new FontFace("Comfortaa", "url(https://fonts.googleapis.com/css2?family=Comfortaa:wght@400&display=swap)");

    font.load().then(() => {
      document.fonts.add(font);
      setFontLoaded(true);
    }).catch(() => {
      setFontLoaded(true); // Show text even if font fails to load
    });
  }, []);

  return (
    <div className="logo">
      <NavLink to="/">
        <div style={{ display: 'flex', alignItems: isHomePage ? 'center' : 'flex-end', gap: '.5rem' }}>
          {isHomePage ? (
            <img style={{ width: '4rem' }} src={LogoWhiteIcon} alt="logo" />
          ) : (
            <img style={{ width: '3rem', height: '3rem' }} src={LogoIcon} alt="logo" />
          )}
          {fontLoaded && (
            <h1 className={`gradient-text ${isSmallScreen ? 'hide-on-small-screen' : ''}`}>
              {isHomePage ? 'ReCoop Rentals' : 'ReCoop'}
            </h1>
          )}
        </div>
      </NavLink>
    </div>
  );
};

export default Logo;
