import './Landing.css';
import turningPost from '../../assets/blog-img copy.jpg'
import introPost from '../../assets/blog-img.jpg'
import { Link } from 'react-router-dom';
import { useEffect } from 'react';

interface LoadSubstackScriptProps {
  url: string;
  settings: {
    substackUrl: string;
    posts: number;
    layout: string;
  };
}

const LoadSubstackScript: React.FC<LoadSubstackScriptProps> = ({ url, settings }) => {
  useEffect(() => {
    // Configure the Substack feed
    (window as any).SubstackFeedWidget = settings;

    // Create script element
    const script = document.createElement('script');
    script.src = url;
    script.async = true;

    // Append script to the body
    document.body.appendChild(script);

    // Cleanup the script when the component is unmounted
    return () => {
      document.body.removeChild(script);
    };
  }, [url, settings]);

  return null;
};

const Landing: React.FC = () => {
  const substackSettings = {
    substackUrl: "recooprentals.substack.com",
    posts: 3,
    layout: "center"
  };

  return (
    <div className='landing'>
      <h2 style={{fontWeight: 400, marginBlockEnd: '.25rem'}}>ReCoop Roundup</h2>
      <p style={{margin: 0, fontSize: '1rem'}}>Want the inside scoop behind what we’re building? Follow our journey by reading our blog.</p>
      <div id="substack-feed-embed" style={{marginBlockStart: '1rem'}}></div>
      <LoadSubstackScript
        url="https://substackapi.com/embeds/feed.js"
        settings={substackSettings}
      />
    </div>
  );
}

export default Landing;
