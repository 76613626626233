import './NestEggList.css'
import React from 'react';
import { useWallet } from "@aptos-labs/wallet-adapter-react";
import { NestEggCard } from '../index';
import { NestEgg } from '../../types';
import { config } from '../../config';
import { AptosClient } from "aptos";
import { useFlashMessage } from '../../components/FlashMessage/FlashMessageContext';

interface NestEggListProps {
  onSelect: (nestEgg: NestEgg | null) => void;
  nestEggList: NestEgg[];
  tokenSelected: NestEgg | null;
  handleMintCompleted: () => void;
}

const client = new AptosClient(config.nodeUrl);

const NestEggList: React.FC<NestEggListProps> = ({ onSelect, tokenSelected, nestEggList, handleMintCompleted }) => {
  const { account, signAndSubmitTransaction } = useWallet();
  const { setFlashMessage } = useFlashMessage();

  const displayFlashMessage = (message: string, type: string) => {
    setFlashMessage({ message, type });
  };

  const handleCardClick = (selectedNestEgg: NestEgg, selected: boolean) => {
    if (selected) {
      onSelect(selectedNestEgg);
    } else {
      onSelect(null);
    }
  };

  const mintToken = async () => {
    if (!account) return [];
    const payload = {
      type: "entry_function_payload",
      function: `${config.creatorAddress}::${config.collectionModule}::${config.collectionNewToken}`,
      type_arguments: [],
      arguments: [
        config.creatorAddress,
        config.collectionName
      ]
    }

    try {
      // sign and submit transaction to chain
      const response = await signAndSubmitTransaction(payload);
      // wait for transaction
      await client.waitForTransaction(response.hash);
      displayFlashMessage('1x Silver Pass minted!', 'success');
      handleMintCompleted();
    } catch (error: any) {
      console.log(error);
      displayFlashMessage('There was an error.', 'error');
    } finally {
      console.log("Done")
    }
  }

  return (
    <div className='nestegg-list'>
      {!nestEggList || nestEggList.length === 0 ? (
        <div className='box'>
          <h2>No Silver Passes found.</h2>
          <button className='center' onClick={mintToken}>Free Silver Pass</button>
        </div>
      ) : (
        <div className="grid">
            <>
              {nestEggList.map((token) => (
                <NestEggCard
                  key={token.name}
                  token={token}
                  selected={tokenSelected?.name === token.name}
                  onClick={handleCardClick}
                />
              ))}
            </>
        </div>
      )}
    </div>
  );
}

export default NestEggList;
