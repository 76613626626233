import './UnitCard.css';
import { Link } from 'react-router-dom';
import { Unit } from '../../types';
import { useState, useEffect, useCallback } from 'react';

interface UnitCardProps {
  key: number;
  unit: Unit;
  startDate: string | null;
  endDate: string | null;
  guests: number | null;
}

const UnitCard: React.FC<UnitCardProps> = ({ unit, startDate, endDate, guests }) => {
  const [imageLoaded, setImageLoaded] = useState<boolean>(false);

  const getImagePath = useCallback(() => {
    const city = unit.property.address.city.replace(' ', '_');
    const propertyNumber = (unit.id - 1) % 5;
    const imageNumber = '0'; // You can replace this with the desired image number
    return `/Test_Properties/${city}/${propertyNumber}/${imageNumber}.png`;
  }, [unit]);

  useEffect(() => {
    const img = new Image();
    img.src = getImagePath();
    img.onload = () => {
      setImageLoaded(true);
    };
  }, [getImagePath]);

  if (!imageLoaded) {
    return <div className='unit-card-placeholder'></div>;
  }

  return (
    <Link to={`/booking-archive/book/${unit.id}?start_date=${startDate}&end_date=${endDate}&guests=${guests}`}>
      <div className='unit-card'>
          <img src={getImagePath()} alt={unit.id.toString()} />
        <div className="details">
          <p>{`${unit.property.address.city}, ${unit.property.address.state_province}`}</p>
          <p id='unit-config'>{`${unit.num_bedrooms}bed - ${unit.num_bathrooms}bath`}</p>
          <p id="price-night">${unit.min_rent_amount_night} <span style={{fontWeight: '400'}}>night</span></p>
        </div>
      </div>
    </Link>
  );
}

export default UnitCard;
